import { inject, injectable } from 'tsyringe';
import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { NoteCreateResponse, NoteDeleteResponse, NoteUpdateResponse, NotesListResponse } from './interfaces/Note.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputMachineNoteCreate,
  InputMachineNoteUpdate,
  InputPagingOptions,
  MutationMachineNoteDeleteArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface NoteClientListOptions {
  machineId: string;
  paginationOptions: InputPagingOptions;
}
export type NoteClientCreateOptions = InputMachineNoteCreate;
export type NoteClientUpdateOptions = InputMachineNoteUpdate;
export type NoteClientDeleteOptions = MutationMachineNoteDeleteArgs;

@injectable()
export class NoteClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    machineId,
    paginationOptions,
  }: NoteClientListOptions): Promise<ApolloQueryResult<NotesListResponse>> =>
    this.client.query({
      query: gql`
        query MachineNotes($machineId: ID!, $paginationOptions: InputPagingOptions!) {
          machine(id: $machineId) {
            data {
              id
              notes(paginationOptions: $paginationOptions) {
                data {
                  id
                  machineId
                  createdAt
                  updatedAt
                  description
                  isUpdated
                  createdBy {
                    id
                    displayName
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
          }
        }
      `,
      variables: {
        machineId,
        paginationOptions,
      },
    });

  public create = async ({
    description,
    machineId,
  }: NoteClientCreateOptions): Promise<FetchResult<NoteCreateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation MachineNoteCreate($input: InputMachineNoteCreate!) {
          machineNoteCreate(input: $input) {
            data {
              id
              machineId
              createdAt
              updatedAt
              description
              isUpdated
              createdBy {
                id
                displayName
              }
            }
          }
        }
      `,
      variables: {
        input: {
          description,
          machineId,
        },
      },
    });

  public update = async ({
    id,
    description,
    machineId,
  }: NoteClientUpdateOptions): Promise<FetchResult<NoteUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation MachineNoteUpdate($input: InputMachineNoteUpdate!) {
          machineNoteUpdate(input: $input) {
            data {
              id
              machineId
              createdAt
              updatedAt
              description
              isUpdated
              createdBy {
                id
                displayName
              }
            }
          }
        }
      `,
      variables: {
        input: {
          id,
          description,
          machineId,
        },
      },
    });

  public delete = async ({ id, machineId }: NoteClientDeleteOptions): Promise<FetchResult<NoteDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation MachineNoteDelete($id: ID!, $machineId: String!) {
          machineNoteDelete(id: $id, machineId: $machineId) {
            data
          }
        }
      `,
      variables: {
        id,
        machineId,
      },
    });
}
