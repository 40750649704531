export const privacyPolicyStyle = `
  .lst-kix_list_2-6 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '\u2022  ';
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  li.li-bullet-3:before {
    margin-left: -18.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18.1pt;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  li.li-bullet-8:before {
    margin-left: -35pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 35pt;
  }
  li.li-bullet-9:before {
    margin-left: -18.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18.1pt;
  }
  li.li-bullet-6:before {
    margin-left: -21pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21pt;
  }
  li.li-bullet-7:before {
    margin-left: -18.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18.1pt;
  }
  li.li-bullet-5:before {
    margin-left: -35pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 35pt;
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: '\u25cf  ';
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '\u2022  ';
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_1-4 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_1-7 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_1-5 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '\u2022  ';
  }
  li.li-bullet-0:before {
    margin-left: -21.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21.1pt;
  }
  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  li.li-bullet-4:before {
    margin-left: -18.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18.1pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, upper-roman) '. ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '' counter(lst-ctn-kix_list_2-1, decimal) '. ';
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_list_1-8 > li:before {
    content: '\u2022  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '\u25cf  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '\u2022  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c25 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 10pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c6 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c48 {
    color: #2a2a2a;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c24 {
    margin-left: 5.8pt;
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.3pt;
  }
  .c35 {
    margin-left: 23.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.7pt;
  }
  .c22 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.8pt;
  }
  .c61 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.7pt;
  }
  .c26 {
    margin-left: 63pt;
    padding-top: 2pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c60 {
    margin-left: 17pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c16 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.3pt;
  }
  .c27 {
    margin-left: 26.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c31 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c13 {
    margin-left: 23.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c62 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0374999999999999;
    text-align: left;
    margin-right: 8.3pt;
  }
  .c33 {
    margin-left: 23.9pt;
    padding-top: 2pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.3pt;
  }
  .c37 {
    margin-left: 23.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 6pt;
  }
  .c46 {
    margin-left: 118.7pt;
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: center;
    margin-right: 118.2pt;
  }
  .c15 {
    margin-left: 26.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.7pt;
  }
  .c45 {
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
    margin-right: 5.4pt;
  }
  .c72 {
    padding-top: 2pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.4pt;
  }
  .c66 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.6pt;
  }
  .c43 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.7pt;
  }
  .c64 {
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c21 {
    margin-left: 23.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1708333333333334;
    text-align: left;
  }
  .c32 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.3pt;
  }
  .c50 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.3pt;
  }
  .c59 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.4pt;
  }
  .c30 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.5pt;
  }
  .c76 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
    margin-right: 5.4pt;
  }
  .c71 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0374999999999999;
    text-align: left;
    margin-right: 7.8pt;
  }
  .c19 {
    margin-left: 23.9pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
  }
  .c63 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0374999999999999;
    text-align: left;
  }
  .c17 {
    margin-left: 21.1pt;
    padding-top: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c9 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c54 {
    padding-top: 2pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
  }
  .c10 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c20 {
    padding-top: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c14 {
    padding-top: 8.4pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c8 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c74 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: left;
  }
  .c29 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c12 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c23 {
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c77 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: justify;
  }
  .c58 {
    padding-top: 9.2pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c51 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
  }
  .c47 {
    padding-top: 4.7pt;
    padding-bottom: 0pt;
    line-height: 1.1666666666666667;
    text-align: left;
  }
  .c70 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
  }
  .c65 {
    padding-top: 8.5pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c41 {
    background-color: #ffffff;
    max-width: 454.3pt;
    padding: 70.8pt 70.8pt 56.7pt 70.8pt;
  }
  .c52 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c44 {
    margin-left: 63pt;
    padding-left: 0pt;
  }
  .c1 {
    padding: 0;
    margin: 0;
  }
  .c2 {
    margin-left: 23.8pt;
    padding-left: 17pt;
  }
  .c18 {
    margin-left: 23.8pt;
    padding-left: 0.1pt;
  }
  .c5 {
    border: 1px solid black;
    margin: 5px;
  }
  .c39 {
    color: inherit;
    text-decoration: inherit;
  }
  .c11 {
    margin-left: 23.8pt;
    padding-left: 3.1pt;
  }
  .c67 {
    margin-left: 41.8pt;
    padding-left: 0.1pt;
  }
  .c36 {
    color: #000000;
    font-size: 10pt;
  }
  .c7 {
    height: 11pt;
  }
  .c40 {
    margin-left: 40.8pt;
  }
  .c69 {
    margin-left: 5.8pt;
  }
  .c28 {
    margin-left: 26.9pt;
  }
  .c56 {
    color: #333333;
  }
  .c34 {
    margin-left: 18.1pt;
  }
  .c55 {
    padding-left: 3pt;
  }
  .c53 {
    margin-left: 21.1pt;
  }
  .c68 {
    margin-left: 17pt;
  }
  .c57 {
    color: #0000ff;
  }
  .c49 {
    font-size: 10pt;
  }
  .c38 {
    margin-left: 23.9pt;
  }
  .c73 {
    margin-right: 5.6pt;
  }
  .c75 {
    margin-right: 5.3pt;
  }
  .c42 {
    font-weight: 700;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: 'Georgia';
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 0pt;
    font-family: 'Arial';
    line-height: 1;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: 'Arial';
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
  }
`;
