import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Reminder } from '../../interfaces/Reminder.types';
import { ReminderModalsActions } from './reminderModalsActions';
import { Optional } from 'lib/types/Optional';

export interface ReminderModalsState {
  createReminder: {
    visible: boolean;
    isLoading: boolean;
  };
  deleteReminder: {
    reminderToDelete: Optional<Reminder>;
    visible: boolean;
    isLoading: boolean;
  };
}

export const initialState: ReminderModalsState = {
  createReminder: {
    visible: false,
    isLoading: false,
  },
  deleteReminder: {
    reminderToDelete: null,
    visible: false,
    isLoading: false,
  },
};

export const reminderModalsReducer = (state = initialState, action: AnyAction): ReminderModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case ReminderModalsActions.SHOW_DELETE_REMINDER_MODAL: {
        const { reminderToDelete } = action.payload;

        draft.deleteReminder.visible = true;
        draft.deleteReminder.reminderToDelete = reminderToDelete;

        return draft;
      }

      case ReminderModalsActions.HIDE_DELETE_REMINDER_MODAL: {
        draft.deleteReminder.visible = false;
        draft.deleteReminder.reminderToDelete = null;

        return draft;
      }

      case ReminderModalsActions.DELETE_REMINDER_MODAL_IS_LOADING: {
        draft.deleteReminder.isLoading = true;
        return draft;
      }

      case ReminderModalsActions.DELETE_REMINDER_MODAL_IS_NOT_LOADING: {
        draft.deleteReminder.isLoading = false;
        return draft;
      }

      case ReminderModalsActions.SHOW_REMINDER_CREATE_MODAL: {
        draft.createReminder.visible = true;
        return draft;
      }

      case ReminderModalsActions.HIDE_REMINDER_CREATE_MODAL: {
        draft.createReminder.visible = false;
        return draft;
      }

      case ReminderModalsActions.CREATE_REMINDER_MODAL_IS_LOADING: {
        draft.createReminder.isLoading = true;
        return draft;
      }

      case ReminderModalsActions.CREATE_REMINDER_MODAL_IS_NOT_LOADING: {
        draft.createReminder.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
