import { NotificationType } from '../../interfaces/Notification.types';
import { StyledNotificationIcon } from './NotificationIcon.styles';
import { NotificationSeverity } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ReactComponent as WarningCritical } from 'app/assets/images/notifications/warning-critical.svg';
import { ReactComponent as Warning } from 'app/assets/images/notifications/warning.svg';
import { ReactComponent as Information } from 'app/assets/images/notifications/information.svg';
import { ReactComponent as Reminder } from 'app/assets/images/notifications/reminder.svg';

export interface NotificationIcon {
  type?: NotificationType;
  severity?: NotificationSeverity;
}

export const NotificationIcon = ({ type, severity }: NotificationIcon): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (type === NotificationType.Reminder) {
      return (
        <span>
          <Reminder className="notification-icon__icon" />
        </span>
      );
    }
    if (severity === NotificationSeverity.Alert) {
      return (
        <span>
          <WarningCritical className="notification-icon__icon" />
        </span>
      );
    }
    if (severity === NotificationSeverity.NeedForAction) {
      return (
        <span>
          <Warning className="notification-icon__icon" />
        </span>
      );
    }
    if (severity === NotificationSeverity.Informative) {
      return (
        <span>
          <Information className="notification-icon__icon" />
        </span>
      );
    }

    return (
      <span>
        <WarningCritical className="notification-icon__icon" />
      </span>
    );
  };

  return (
    <StyledNotificationIcon className="notification-icon__content">
      <Icon />
    </StyledNotificationIcon>
  );
};
