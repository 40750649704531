import { isNil } from 'lodash-es';
import { NotificationType } from './interfaces/Notification.types';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export class NotificationViewModel {
  private notification: Notification;

  constructor(notification: Notification) {
    this.notification = notification;
  }

  public get isExpandable(): boolean {
    const moreInformationIsNil = isNil(this.moreInformation);
    const moreInformationIsAnEmptyString =
      typeof this.moreInformation === 'string' && this.moreInformation.trim().length <= 0;
    const isExpandable = !moreInformationIsNil && !moreInformationIsAnEmptyString;

    return isExpandable;
  }

  public get moreInformation(): string | null {
    switch (this.notification.type) {
      case NotificationType.Reminder:
        return (JSON.parse(this.notification?.data || '{}') as { description?: string })?.description || '';

      default:
        return this.notification.message?.consequence || '';
    }
  }

  public get description(): string | undefined {
    switch (this.notification.type) {
      case NotificationType.Reminder:
        return (JSON.parse(this.notification?.data || '{}') as { topic?: string })?.topic || '';

      default:
        return this.notification.message?.description || '';
    }
  }
}
