import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { NotificationList, NotificationListAvailableFilters } from '../../interfaces/Notification.types';
import {
  IGetMachineListFilterErrorAction,
  IGetMachineListFilterRequestAction,
  IGetMachineListFilterSuccessAction,
  IGetNotificationListErrorAction,
  IGetNotificationListFiltersErrorAction,
  IGetNotificationListFiltersSuccessAction,
  IGetNotificationListMoreDataErrorAction,
  IGetNotificationListMoreDataRequestAction,
  IGetNotificationListMoreDataSuccessAction,
  IGetNotificationListRequestAction,
  IGetNotificationListSuccessAction,
  NotificationListActions,
} from './notificationListActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { MachinesAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';

export function* getNotificationListSaga(
  action: IGetNotificationListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<NotificationList>
  | PutEffect<IGetNotificationListSuccessAction>
  | PutEffect<IGetNotificationListErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.list, action.payload);
    yield* put(NotificationListActions.getNotificationListSuccess(response));
  } catch (error) {
    yield* put(
      NotificationListActions.getNotificationListError({
        error,
      })
    );
  }
}

export function* getNotificationListMoreDataSaga(
  action: IGetNotificationListMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<NotificationList>
  | PutEffect<IGetNotificationListMoreDataSuccessAction>
  | PutEffect<IGetNotificationListMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.list, action.payload);
    yield* put(NotificationListActions.getNotificationListMoreDataSuccess(response));
  } catch (error) {
    yield* put(
      NotificationListActions.getNotificationListMoreDataError({
        error,
      })
    );
  }
}

export function* getNotificationListFiltersSaga(): Generator<
  | GetContextEffect
  | CallEffect<NotificationListAvailableFilters>
  | PutEffect<IGetNotificationListFiltersSuccessAction>
  | PutEffect<IGetNotificationListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.availableFilters);

    yield* put(NotificationListActions.getNotificationListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      NotificationListActions.getNotificationListFiltersError({
        error,
      })
    );
  }
}

export function* getMachineListFilterSaga(
  action: IGetMachineListFilterRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachinesAvailableToFilter>
  | PutEffect<IGetMachineListFilterSuccessAction>
  | PutEffect<IGetMachineListFilterErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machineResponse = yield* call(notificationService.machineListFilter, action.payload);

    yield* put(NotificationListActions.getMachineListFilterSuccess(machineResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      NotificationListActions.getMachineListFilterError({
        error,
      })
    );
  }
}

export function* notificationListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(NotificationListActions.GET_NOTIFICATION_LIST_REQUEST, getNotificationListSaga);
  yield* takeLatest(NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_REQUEST, getNotificationListMoreDataSaga);
  yield* takeLatest(NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_REQUEST, getNotificationListFiltersSaga);
  yield* takeLatest(
    NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST,
    getMachineListFilterSaga
  );
}
