import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { i18n as i18next } from 'i18next';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../config/route-paths';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SortableColumnTitle } from 'lib/components/LegacyTable/SortableColumnTitle/SortableColumnTitle';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { NotificationIcon } from 'app/modules/notification/components/NotificationIcon/NotificationIcon';

interface HighPriorityNotificationsColumnsOptions {
  t: TFunction;
  i18n: i18next;
}

export const getHighPriorityNotificationsColumns = ({
  t,
  i18n,
}: HighPriorityNotificationsColumnsOptions): ColumnsType<Notification> => [
  {
    width: '10%',
    render: ({ severity, type }): ReactNode => <NotificationIcon severity={severity} type={type} />,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<Notification>(
        t('overview.highPriorityNotifications.table.machines'),
        false,
        'machineName',
        sortColumns
      ),
    dataIndex: ['machine', 'name'],
    key: 'machineName',
    width: '30%',
    sorter: false,
    defaultSortOrder: 'ascend',
    render: (machineName: string, notification: Notification): JSX.Element => (
      <Link
        to={RoutePaths.MACHINE_DETAILS.replace(':id', notification.machine.id)}
        className="legacy-table__link--no-styling"
      >
        {machineName}
      </Link>
    ),
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<Notification>(
        t('overview.highPriorityNotifications.table.timestamp'),
        false,
        'timestamp',
        sortColumns
      ),
    dataIndex: 'timestamp',
    width: '30%',
    key: 'timestamp',
    sorter: false,
    defaultSortOrder: 'ascend',
    render: (timestamp: string): JSX.Element => (
      <div>
        {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.DATE)}
        <br />
        {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.TIME)}
      </div>
    ),
  },
  {
    title: t('overview.highPriorityNotifications.table.description'),
    dataIndex: 'description',
    key: 'description',
    width: '30%',
    render: (notification: Notification): JSX.Element => <>{notification.message?.description || ''}</>,
  },
];
