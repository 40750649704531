import { singleton } from 'tsyringe';
import {
  NoteClient,
  NoteClientCreateOptions,
  NoteClientDeleteOptions,
  NoteClientListOptions,
  NoteClientUpdateOptions,
} from './NoteClient';
import { NoteCreateResponse, NoteDeleteResponse, NoteUpdateResponse, NotesListResponse } from './interfaces/Note.types';
import { Optional } from 'lib/types/Optional';

export type NoteServiceListOptions = NoteClientListOptions;
export type NoteServiceCreateOptions = NoteClientCreateOptions;
export type NoteServiceUpdateOptions = NoteClientUpdateOptions;
export type NoteServiceDeleteOptions = NoteClientDeleteOptions;

@singleton()
export class NoteService {
  constructor(private noteClient: NoteClient) {}

  public list = async ({ machineId, paginationOptions }: NoteServiceListOptions): Promise<NotesListResponse> => {
    const { data } = await this.noteClient.list({
      machineId,
      paginationOptions,
    });

    return data;
  };

  public create = async ({
    machineId,
    description,
  }: NoteServiceCreateOptions): Promise<Optional<NoteCreateResponse>> => {
    const { data } = await this.noteClient.create({
      machineId,
      description,
    });

    return data;
  };

  public update = async ({
    id,
    machineId,
    description,
  }: NoteServiceUpdateOptions): Promise<Optional<NoteUpdateResponse>> => {
    const { data } = await this.noteClient.update({
      id,
      machineId,
      description,
    });

    return data;
  };

  public delete = async ({ id, machineId }: NoteServiceDeleteOptions): Promise<Optional<NoteDeleteResponse>> => {
    const { data } = await this.noteClient.delete({ id, machineId });

    return data;
  };
}
