import styled from 'styled-components';
import { AutoComplete } from 'lib/components/AutoComplete/AutoComplete';

export const StyledTopicSuggestionAutoComplete = styled(AutoComplete)`
  .auto-complete__custom-input {
    input {
      font-size: ${(props): string => props.theme.fontSizes.p1};

      &::placeholder {
        ${(props): string => props.theme.fontStyles.p1.medium};
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }
  }

  &.ant-select.ant-select-single,
  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      height: 44px;
    }

  .ant-select-selection-placeholder,
  .ant-select-selection-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props): string => props.theme.fontStyles.p1.medium}
  }

  .ant-select-clear {
    background: none;
  }

  .ant-select-arrow {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-size: 1rem;
    width: fit-content;
    margin-top: -5px;
  }

  .ant-select-dropdown-hidden {
    display: block;
  }
`;
