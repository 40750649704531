import styled from 'styled-components';

export const StyledOverview = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('overview')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('overview')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('overview')}

  min-height: inherit;

  .overview__header {
    background-color: transparent;
  }

  .overview__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin: 0;
  }

  .overview__sub-header {
    font-family: ${(props): string => props.theme.fonts.heading3};
    font-size: ${(props): string => props.theme.fontSizes.heading3};
    color: ${(props): string => props.theme.colors.black};
    line-height: ${(props): string => props.theme.lineHeights.heading3};
    font-weight: ${(props): number => props.theme.fontWeights.heading3};
  }

  .overview__description,
  .overview__description p {
    ${(props): string => props.theme.fontStyles.p1.news}
    color: ${(props): string => props.theme.colors.black};
  }
`;
