import { Action } from 'redux';
import { NotificationClientListOptions } from 'app/modules/notification/NotificationClient';
import { NotificationList } from 'app/modules/notification/interfaces/Notification.types';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export type IGetPriorityNotificationsRequestOptions = NotificationClientListOptions;

export type IGetPriorityNotificationsRequestAction = Action<
  typeof PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_REQUEST
> & {
  payload: NotificationClientListOptions;
};

export type IGetPriorityNotificationsSuccessOptions = NotificationList;
export type IGetPriorityNotificationsSuccessAction = Action<
  typeof PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_REQUEST
> & {
  payload: NotificationList;
};

export type IGetPriorityNotificationsErrorAction = IErrorAction<
  typeof PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_ERROR
>;

export class PriorityNotificationsActions {
  public static GET_PRIORITY_NOTIFICATIONS_REQUEST = 'PRIORITY_NOTIFICATIONS::GET_PRIORITY_NOTIFICATIONS_REQUEST';

  public static GET_PRIORITY_NOTIFICATIONS_SUCCESS = 'PRIORITY_NOTIFICATIONS::GET_PRIORITY_NOTIFICATIONS_SUCCESS';

  public static GET_PRIORITY_NOTIFICATIONS_ERROR = 'PRIORITY_NOTIFICATIONS::GET_PRIORITY_NOTIFICATIONS_ERROR';

  public static RESET_STATE = 'PRIORITY_NOTIFICATIONS::RESET_STATE';

  public static getPriorityNotificationsRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetPriorityNotificationsRequestOptions): IGetPriorityNotificationsRequestAction {
    return {
      type: PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getPriorityNotificationsSuccess(
    data: IGetPriorityNotificationsSuccessOptions
  ): IGetPriorityNotificationsSuccessAction {
    return {
      type: PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_SUCCESS,
      payload: data,
    };
  }

  public static getPriorityNotificationsError({
    error,
  }: IActionCreatorErrorOptions): IGetPriorityNotificationsErrorAction {
    return {
      type: PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof PriorityNotificationsActions.RESET_STATE> {
    return {
      type: PriorityNotificationsActions.RESET_STATE,
    };
  }
}
