import { useTranslation } from 'react-i18next';
import React from 'react';
import { StyledHighPriorityNotifications } from './HighPriorityNotifications.styles';
import { getHighPriorityNotificationsColumns } from './columns/HighPriorityNotifications.columns';
import { LegacyTable } from 'lib/components/LegacyTable/LegacyTable';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface HighPriorityNotificationsProps {
  notifications: Notification[];
  isLoading: boolean;
}

export const HighPriorityNotifications: React.FC<HighPriorityNotificationsProps> = ({
  notifications,
  isLoading,
}): JSX.Element => {
  const { i18n, t } = useTranslation();

  const tableColumns = getHighPriorityNotificationsColumns({ t, i18n });

  return (
    <StyledHighPriorityNotifications className="high-priority-notifications">
      <LegacyTable
        dataSource={notifications}
        loading={isLoading}
        className="high-priority-notifications__table"
        columns={tableColumns}
        rowKey="id"
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </StyledHighPriorityNotifications>
  );
};
