/* eslint-disable max-len */
export const privacyPolicyContentDe = `
<html>
  <p class="c46">
    <span class="c25">Datenschutzerkl&auml;rung f&uuml;r das K&auml;rcher Equipment Management Webportal</span>
  </p>
  <p class="c7 c46"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0 start" start="1">
    <li class="c0 c11 li-bullet-0">
      <span class="c36 c42 c52">Name und Anschrift des Verantwortlichen und des Datenschutzbeauftragten</span>
    </li>
  </ol>
  <p class="c8 c7"><span class="c6"></span></p>
  <p class="c0 c69"><span class="c4">Name und Kontaktdaten des Verantwortlichen:</span></p>
  <p class="c10 c7"><span class="c4"></span></p>
  <p class="c0">
    <span class="c4"
      >Alfred K&auml;rcher SE &amp; Co. KG<br />Alfred-K&auml;rcher-Strasse 28-40<br />71364 Winnenden</span
    >
  </p>
  <p class="c0">
    <span class="c49">Tel. +49 7195 / 14-0<br />E-Mail: </span
    ><span class="c52 c49"><a class="c39" href="mailto:info@karcher.com">info@karcher.com</a></span>
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c63">
    <span class="c4"
      >(nachfolgend: K&auml;rcher) verf&auml;hrt bei allen Vorg&auml;ngen der Datenverarbeitung (z. B. Erhebung,
      Verarbeitung und &Uuml;bermittlung) nach den gesetzlichen Vorschriften.</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <p class="c69 c71">
    <span class="c4"
      >Die nachfolgende Erkl&auml;rung gibt Ihnen einen &Uuml;berblick dar&uuml;ber, welche Art von Daten auf und
      &uuml;ber unser Web Portal unter https://em.kaercher.com/ (nachfolgend: Plattform) erhoben und in welcher Weise
      verarbeitet werden, welche Sicherheitsma&szlig;nahmen K&auml;rcher zum Schutz Ihrer Daten ergreift und wie Sie
      Ihre Rechte wahrnehmen k&ouml;nnen.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c62">
    <span class="c48"
      >Bei Fragen zum Thema Datenschutz, und hinsichtlich Ihrer Rechte als betroffene Person erreichen Sie den
      K&auml;rcher-Datenschutzbeauftragten unter:</span
    >
  </p>
  <p class="c7 c62"><span class="c4"></span></p>
  <p class="c0">
    <span class="c4"
      >Alfred K&auml;rcher SE &amp; Co. KG<br />Datenschutzbeauftragter<br />Alfred-K&auml;rcher-Strasse 28-40<br />71364
      Winnenden</span
    >
  </p>
  <p class="c0">
    <span class="c49">E-Mail: </span
    ><span class="c52 c49"
      ><a class="c39" href="mailto:datenschutzbeauftragter@de.kaercher.com"
        >datenschutzbeauftragter@de.kaercher.com</a
      ></span
    ><span class="c4">&nbsp;</span>
  </p>
  <ol class="c1 lst-kix_list_2-0" start="2">
    <li class="c11 c58 li-bullet-0"><span class="c25">Allgemeines zur Datenverarbeitung</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="1">
    <li class="c0 c11 li-bullet-0"><span class="c6">Umfang der Verarbeitung personenbezogener Daten</span></li>
  </ol>
  <p class="c66 c28">
    <span class="c4"
      >Soweit nicht anders angegeben, verarbeiten wir Ihre personenbezogenen Daten als Nutzer unserer Plattform
      (nachfolgend: Betroffener), um Ihnen eine funktionsf&auml;hige und nutzerfreundliche Plattform sowie unsere
      jeweiligen Dienste bereitstellen und erbringen zu k&ouml;nnen.</span
    >
  </p>
  <p class="c51 c7 c28 c73"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="2">
    <li class="c23 c11 li-bullet-0"><span class="c6">Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener Daten eine Einwilligung der betroffenen Person
      einholen, dient Art. 6 Abs. 1 lit. a Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c43">
    <span class="c4"
      >Bei der Verarbeitung von personenbezogenen Daten, die zur Nutzung der Plattform erforderlich ist, dient Art. 6
      Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch f&uuml;r Verarbeitungsvorg&auml;nge, die zur
      Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich sind.</span
    >
  </p>
  <p class="c28 c30">
    <span class="c4"
      >Soweit eine Verarbeitung personenbezogener Daten zur Erf&uuml;llung einer rechtlichen Verpflichtung
      erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c59">
    <span class="c4"
      >Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten
      erforderlich und &uuml;berwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
      erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage f&uuml;r die
      Verarbeitung.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="3">
    <li class="c0 c11 li-bullet-0"><span class="c6">Datenl&ouml;schung und Speicherdauer</span></li>
  </ol>
  <p class="c15">
    <span class="c4"
      >Soweit nicht anders angegeben, werden personenbezogenen Daten gesperrt oder gel&ouml;scht, sobald der Zweck der
      Speicherung entf&auml;llt.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c32">
    <span class="c4"
      >Eine Speicherung kann dar&uuml;ber hinaus erfolgen, wenn dies durch den europ&auml;ischen oder nationalen
      Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche
      unterliegt, vorgesehen wurde. Eine Sperrung oder L&ouml;schung der Daten erfolgt auch dann, wenn eine so
      vorgeschriebene Speicherfrist abl&auml;uft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
      Daten f&uuml;r einen Vertragsabschluss oder eine Vertragserf&uuml;llung besteht.</span
    >
  </p>
  <p class="c16 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="3">
    <li class="c11 c14 li-bullet-0"><span class="c25">Bereitstellung der Plattform</span></li>
  </ol>
  <p class="c7 c12"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="4">
    <li class="c0 c11 li-bullet-0"><span class="c6">Beschreibung und Umfang der Datenverarbeitung</span></li>
  </ol>
  <p class="c28 c47">
    <span class="c4"
      >Bei jedem Aufruf unserer Plattform erfasst unser System automatisiert Daten und Informationen vom
      Computersystem des aufrufenden Rechners.</span
    >
  </p>
  <p class="c7 c17"><span class="c4"></span></p>
  <p class="c0 c28"><span class="c4">Folgende Daten werden hierbei erhoben:</span></p>
  <p class="c29 c7 c53"><span class="c4"></span></p>
  <ul class="c1 lst-kix_list_2-2 start">
    <li class="c0 c44 li-bullet-1"><span class="c4">Datum und Uhrzeit der Anforderung</span></li>
    <li class="c26 li-bullet-2"><span class="c4">Name der angeforderten Datei</span></li>
    <li class="c26 li-bullet-1"><span class="c4">Zeitpunkt des letzten Logins</span></li>
    <li class="c26 li-bullet-2"><span class="c4">Seite, von der aus die Datei angefordert wurde</span></li>
    <li class="c26 li-bullet-1"><span class="c4">Zugriffsstatus (Datei &uuml;bertragen, Datei nicht gefunden, etc.)</span></li>
    <li class="c26 li-bullet-1"><span class="c4">verwendeter Webbrowser, Sprache und verwendetes Betriebssystem</span></li>
    <li class="c26 li-bullet-2"><span class="c4">vollst&auml;ndige IP-Adresse des anfordernden Rechners</span></li>
    <li class="c26 li-bullet-2"><span class="c4">&uuml;bertragene Datenmenge</span></li>
  </ul>
  <p class="c0 c7 c53"><span class="c4"></span></p>
  <p class="c0 c28">
    <span class="c4">Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.</span>
  </p>
  <p class="c0 c7 c28"><span class="c4"></span></p>
  <p class="c51 c28">
    <span class="c49 c56"
      >Auch wenn Sie das K&auml;rcher Equipment Management Webportal nicht aktiv nutzen, erfassen und speichern wir
      bei Maschinen mit verbauter K&auml;rcher IoT-Telematikeinheit automatisiert technische Maschinendaten, um Ihnen
      einen schnelleren und besseren Service anbieten zu k&ouml;nnen und unsere Produkte zu verbessern.</span
    >
  </p>
  <p class="c12 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="5">
    <li class="c0 c11 li-bullet-0"><span class="c6">Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></li>
  </ol>
  <p class="c28 c74">
    <span class="c4"
      >Rechtsgrundlage f&uuml;r die vor&uuml;bergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit.
      f DSGVO. Rechtsgrundlage f&uuml;r die Verarbeitung der Maschinendaten ist &ndash; soweit ein Personenbezug
      hergestellt werden kann, ist Art. 6 Abs. 1 lit. f DSGVO.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="6">
    <li class="c0 c11 li-bullet-0"><span class="c6">Zweck der Datenverarbeitung</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >Die vor&uuml;bergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der
      Plattform an den Rechner des Nutzers zu erm&ouml;glichen. Hierf&uuml;r muss die IP-Adresse des Nutzers f&uuml;r
      die Dauer der Sitzung gespeichert bleiben.</span
    >
  </p>
  <p class="c10 c7 c53"><span class="c4"></span></p>
  <p class="c32 c28">
    <span class="c4"
      >Die Speicherung in Logfiles erfolgt, um die Funktionsf&auml;higkeit der Plattform sicherzustellen. Zudem dienen
      uns die Daten zur Optimierung der Plattform und zur Sicherstellung der Sicherheit unserer
      informationstechnischen Systeme.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c76">
    <span class="c4"
      >In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f
      DSGVO.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="7">
    <li class="c0 c11 li-bullet-0"><span class="c6">Dauer der Speicherung</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung nicht mehr
      erforderlich sind.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="4">
    <li class="c8 c11 li-bullet-0"><span class="c25">Registrierung auf und Nutzung der Plattform</span></li>
  </ol>
  <p class="c0 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="8">
    <li class="c0 c18 li-bullet-3"><span class="c6">Beschreibung und Umfang der Datenverarbeitung</span></li>
  </ol>
  <p class="c35">
    <span class="c4"
      >Um die Plattform umfassend nutzen und die f&uuml;r das/die jeweils angemeldete(n) Ger&auml;te verarbeiteten
      Maschinendaten abrufen zu k&ouml;nnen, ist eine Registrierung auf der Plattform und Anmeldung eines Nutzerkontos
      zwingend erforderlich.</span
    >
  </p>
  <p class="c9 c7 c34"><span class="c4"></span></p>
  <p class="c38 c50">
    <span class="c4"
      >Die Registrierung eines Nutzerkontos erfolgt &uuml;ber einen K&auml;rcher Account, bei dem der jeweilige Nutzer
      zusammen mit Ihrer E-Mail-Adresse ein Passwort (nachfolgend: Benutzerdaten) w&auml;hlen k&ouml;nnen.
      Zus&auml;tzlich k&ouml;nnen weitere freiwillige Angaben (z.B. Firmenname und Kontaktdaten) gemacht werden. Die
      Daten werden dabei in eine Eingabemaske eingegeben und an uns &uuml;bermittelt und gespeichert.</span
    >
  </p>
  <p class="c10 c7 c34"><span class="c4"></span></p>
  <p class="c13">
    <span class="c36">F&uuml;r die Weiterleitung von Mitteilungen per SMS oder E-Mail, die von </span
    ><span class="c49">die vom System versendet werden</span
    ><span class="c36">, k&ouml;nnen Telefonnummern und E-Mail-Adressen auf der Plattform hinterlegt </span
    ><span class="c49">werden</span
    ><span class="c4"
      >. &nbsp;Diese werden als sogenannte Operator gespeichert, bei dem Name, Telefonnummer, E-Mail-Adresse und das
      jeweilige Ger&auml;t gespeichert wird von dem die Mitteilungen weitergeleitet werden.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="9">
    <li class="c8 c18 li-bullet-4"><span class="c6">Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></li>
  </ol>
  <p class="c38 c73 c77">
    <span class="c4"
      >Rechtsgrundlage der Verarbeitung der personenbezogenen Daten ist die Vertragserf&uuml;llung (Art. 6 Abs. 1 lit.
      b DSGVO).</span
    >
  </p>
  <ol class="c1 lst-kix_list_2-1" start="10">
    <li class="c18 c23 li-bullet-3"><span class="c6">Zweck der Datenverarbeitung</span></li>
  </ol>
  <p class="c35">
    <span class="c36">Die Anlage eines Nutzerkontos ist f&uuml;r die Zuordnung </span
    ><span class="c49">zum jeweiligen Kundenaccount</span
    ><span class="c4"
      >&nbsp;bzw. der Nutzung der bereitgestellten Funktionen erforderlich. Die Daten werden gegebenenfalls also auch
      beim Einsatz der von dem Nutzer ausgew&auml;hlten Funktionen, insbesondere zur Erbringung der Leistungen aus den
      Allgemeinen Vertragsbedingungen f&uuml;r die Nutzung des K&auml;rcher Equipment Management Kundenportals (AVB)
      verarbeitet.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="11">
    <li class="c0 c18 li-bullet-3"><span class="c6">Dauer der Speicherung</span></li>
  </ol>
  <p class="c13">
    <span class="c4"
      >Bei Anlage eines Nutzerkontos speichern wir die jeweils angegebenen personenbezogenen Daten grunds&auml;tzlich
      solange Sie Ihr Nutzerkonto aufrechterhalten bzw. in dem Nutzerkonto nicht ge&auml;ndert oder gel&ouml;scht
      werden.</span
    >
  </p>
  <p class="c9 c7 c38"><span class="c4"></span></p>
  <p class="c37">
    <span class="c4"
      >Bitte beachten Sie, dass eine L&ouml;schung des jeweiligen Nutzerkontos auf der Plattform ggf. den Verlust des
      Zugriffs auf die Plattform und den Zugriff auf die gespeicherten Daten zur Folge hat.</span
    >
  </p>
  <p class="c7 c8"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="12">
    <li class="c0 c18 li-bullet-4"><span class="c6">L&ouml;schm&ouml;glichkeit</span></li>
  </ol>
  <p class="c38 c66">
    <span class="c4"
      >Nutzer der Plattform haben jederzeit die M&ouml;glichkeit, die dort gespeicherten personenbezogenen Daten zu
      &auml;ndern bzw. diesen selbst wieder zu l&ouml;schen.</span
    >
  </p>
  <p class="c9 c7 c34"><span class="c4"></span></p>
  <p class="c35">
    <span class="c4"
      >Sind die Daten zur Erf&uuml;llung eines Vertrages oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
      erforderlich, ist eine vorzeitige L&ouml;schung der Daten nur m&ouml;glich, soweit nicht vertragliche oder
      gesetzliche Verpflichtungen einer L&ouml;schung entgegenstehen.</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="5">
    <li class="c0 c2 li-bullet-5"><span class="c6">Nutzung von Usersnap</span></li>
  </ol>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="13">
    <li class="c0 c38 c55 li-bullet-6"><span class="c6">Beschreibung und Umfang der Datenverarbeitung</span></li>
  </ol>
  <p class="c32 c28">
    <span class="c4"
      >Wir nutzen auf unserer Plattform das interaktive Feedback-Tool des Anbieters Usersnap (Usersnap GmbH,
      Industriezeile 35, 4020 Linz, &Ouml;sterreich). Dar&uuml;ber k&ouml;nnen Sie uns Ihr Feedback zu der Plattform
      uns unseren Services schicken. Wenn Sie uns Feedback schicken, verarbeitet Usersnap in unserem Auftrag
      personenbezogene Daten von Ihnen. Dazu geh&ouml;rt Ihre </span
    ><span class="c4"
      >Bildschirmaufl&ouml;sung und -gr&ouml;&szlig;e, ein Zeitstempel des Besuchs unserer Webseite, Ihre IP-Adresse,
      Ihre geographische Lage, die URL und Daten zur Art Ihres Endger&auml;ts</span
    ><span class="c4"
      >. Weitere Informationen zum Datenschutz bei Usersnap finden Sie unter folgendem Link:&nbsp;</span
    ><span class="c4"
      ><a
        class="c39"
        href="https://www.google.com/url?q=https://usersnap.com/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1683717285451917&amp;usg=AOvVaw2EDy-7wo8xAPE26l6iXM_F"
        >https://usersnap.com/privacy-policy</a
      ></span
    ><span class="c4">.</span>
  </p>
  <p class="c32 c7 c38"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="14">
    <li class="c0 c18 li-bullet-7"><span class="c6">Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></li>
  </ol>
  <p class="c13">
    <span class="c4"
      >Die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten unter Verwendung des Feedbacktools ist
      Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</span
    >
  </p>
  <p class="c7 c20"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="15">
    <li class="c0 c18 li-bullet-3"><span class="c6">Zweck der Datenverarbeitung</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Wir nutzen die Daten ausschlie&szlig;lich zur Bearbeitung des Feedbacks und zur Verbesserung unserer
      Services.</span
    >
  </p>
  <p class="c7 c60"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="16">
    <li class="c0 c18 li-bullet-7"><span class="c6">Datenl&ouml;schung und Speicherdauer</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Das eingehende Feedbacks sowie ggf. unsere R&uuml;ckmeldungen speichern wir bis zur L&ouml;sung des Feedbacks.
      Danach werden Ihre Feedback-Daten gel&ouml;scht oder anonymisiert.</span
    >
  </p>
  <p class="c0 c7 c38"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="17">
    <li class="c0 c18 li-bullet-7"><span class="c6">Widerrufsm&ouml;glichkeit</span></li>
  </ol>
  <p class="c0 c38">
    <span class="c4">Sie k&ouml;nnen Ihre Einwilligung in diese Datenverarbeitung jederzeit per E-Mail an </span
    ><span class="c4"
      ><a class="c39" href="mailto:datenschutzbeauftragter@de.kaercher.com"
        >datenschutzbeauftragter@de.kaercher.com</a
      ></span
    ><span class="c4">.</span>
  </p>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="6">
    <li class="c0 c2 li-bullet-8">
      <span class="c25">Verwendung von Cookies</span>
    </li>
  </ol>
  <p class="c10 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="18">
    <li class="c0 c18 li-bullet-7"><span class="c6">Beschreibung und Umfang der Datenverarbeitung</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Unsere Plattform ausschlie&szlig;lich technische Cookie und Adobe Analytics. Bei Cookies handelt es sich um
      Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert
      werden. Ruft ein Nutzer eine Plattform auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert
      werden. Dieser Cookie enth&auml;lt eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
      Browsers beim erneuten Aufrufen der Plattform erm&ouml;glicht.</span
    >
  </p>
  <p class="c32 c7 c38"><span class="c4"></span></p>
  <p class="c32 c38">
    <span class="c36"
      >Auf dieser Website kommt au&szlig;erdem Adobe Analytics, ein Webanalysedienst der Adobe Systems Software
      Ireland Limited (&bdquo;Adobe&ldquo;), zum Einsatz. Adobe Analytics verwendet Cookies, um eine Analyse der
      Benutzung der Webseite durch Sie zu erm&ouml;glichen. Werden die durch den Cookie erzeugten Informationen
      &uuml;ber die Benutzung der Website an einen Server von Adobe &uuml;bermittelt, dann ist durch die Einstellungen
      gew&auml;hrleistet, dass die IP-Adresse vor der Geolokalisierung anonymisiert und vor Speicherung durch eine
      generische IP-Adresse ersetzt wird. Im Auftrag des Betreibers dieser Website wird Adobe diese Informationen
      benutzen, um die Nutzung der Website durch die Nutzer auszuwerten, um Reports &uuml;ber die
      Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
      verbundene Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen. Die im Rahmen von Adobe Analytics
      von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Adobe zusammengef&uuml;hrt. Sie
      k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern.
      Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser
      Website vollumf&auml;nglich nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das
      Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Adobe sowie die
      Verarbeitung dieser Daten durch Adobe verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare
      Browser-Plug-In herunterladen und installieren: </span
    ><span class="c52 c49 c57"
      ><a
        class="c39"
        href="https://www.google.com/url?q=http://www.adobe.com/de/privacy/opt-out.html&amp;sa=D&amp;source=editors&amp;ust=1683717285453866&amp;usg=AOvVaw0T3kEm0iQ_Q_W7Q7K7cWDl"
        >http://www.adobe.com/de/privacy/opt-out.html</a
      ></span
    >
  </p>
  <p class="c32 c7 c68"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="19">
    <li class="c0 c18 li-bullet-4"><span class="c6">Rechtsgrundlage f&uuml;r die Datenverarbeitung</span></li>
  </ol>
  <p class="c13">
    <span class="c4"
      >Die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten unter Verwendung funktional oder
      technisch notwendiger Cookies ist Art. 6 Abs. 1 lit. f DSGVO.</span
    >
  </p>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="20">
    <li class="c0 c18 li-bullet-4"><span class="c6">Zweck der Datenverarbeitung</span></li>
  </ol>
  <p class="c13">
    <span class="c4"
      >Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Plattform f&uuml;r die Nutzer zu
      vereinfachen. Einige Funktionen unserer Plattform k&ouml;nnen ohne den Einsatz von Cookies nicht angeboten
      werden. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von
      Nutzerprofilen verwendet. In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der
      personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.</span
    >
  </p>
  <p class="c60 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="21">
    <li class="c0 c18 li-bullet-3"><span class="c6">Widerspruchsm&ouml;glichkeit</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unsere Seite &uuml;bermittelt. Daher
      haben Sie als Nutzer auch die volle Kontrolle &uuml;ber die Verwendung von Cookies. Durch eine &Auml;nderung der
      Einstellungen in Ihrem Internetbrowser k&ouml;nnen Sie die &Uuml;bertragung von Cookies deaktivieren oder
      einschr&auml;nken. Bereits gespeicherte Cookies k&ouml;nnen jederzeit gel&ouml;scht werden. Dies kann auch
      automatisiert erfolgen. Werden Cookies f&uuml;r unsere Plattform deaktiviert, k&ouml;nnen m&ouml;glicherweise
      nicht mehr alle Funktionen der Plattform vollumf&auml;nglich genutzt werden.</span
    >
  </p>
  <p class="c16 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="7">
    <li class="c11 c65 li-bullet-0"><span class="c25">Weitergabe an Dritte</span></li>
  </ol>
  <p class="c7 c10"><span class="c6"></span></p>
  <p class="c31">
    <span class="c4"
      >Innerhalb der K&auml;rcher Unternehmensgruppe tauschen wir personenbezogene Daten konzernintern aus, sofern und
      soweit dies zur Vertragsabwicklung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen gem&auml;&szlig;
      Art. 6 Abs.1 lit.b DSGVO erforderlich ist oder sich ein berechtigtes Interesse gem&auml;&szlig; Art. 6 Abs. 1
      lit. f DSGVO an einer konzerninternen Weitergabe ergibt.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <p class="c22">
    <span class="c4"
      >Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt ohne Ihre ausdr&uuml;ckliche vorherige
      Einwilligung neben den explizit in dieser Datenschutzerkl&auml;rung genannten F&auml;llen lediglich dann, wenn
      es gesetzlich zul&auml;ssig bzw. erforderlich ist.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <p class="c16">
    <span class="c4"
      >Wenn es zur Aufkl&auml;rung einer rechtswidrigen bzw. missbr&auml;uchlichen Nutzung der Plattform oder f&uuml;r
      die Rechtsverfolgung erforderlich ist, werden personenbezogene Daten an die Strafverfolgungsbeh&ouml;rden oder
      andere Beh&ouml;rden sowie ggf. an gesch&auml;digte Dritte weitergeleitet. Dies geschieht jedoch nur, wenn
      Anhaltspunkte f&uuml;r ein gesetzwidriges bzw. missbr&auml;uchliches Verhalten vorliegen. Wir sind zudem
      gesetzlich verpflichtet, auf Anfrage bestimmten &ouml;ffentlichen Stellen Auskunft zu erteilen. Dies sind
      Strafverfolgungsbeh&ouml;rden, Beh&ouml;rden, die bu&szlig;geldbew&auml;hrte Ordnungswidrigkeiten verfolgen, und
      die Finanzbeh&ouml;rden.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c16">
    <span class="c4"
      >Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt, dass (1) die Verarbeitung zur
      Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist, der wir gem&auml;&szlig; Art. 6 Abs. 1 lit. c
      DSGVO i.V.m. nationalen rechtlichen Vorgaben zur Weitergabe von Daten an Strafverfolgungsbeh&ouml;rden
      unterliegen, oder (2) wir ein berechtigtes Interesse daran haben, die Daten</span
    >
  </p>
  <p class="c24">
    <span class="c4"
      >bei Vorliegen von Anhaltspunkten f&uuml;r missbr&auml;uchliches Verhalten oder zur Durchsetzung von
      Rechtsanspr&uuml;chen an die genannten Dritten weiterzugeben und Ihre Rechte und Interessen am Schutz Ihrer
      personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f DSGVO nicht &uuml;berwiegen.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <p class="c61">
    <span class="c4"
      >Um die Dienste auf dieser Plattform anbieten zu k&ouml;nnen, werden Fremdunternehmen und externe Dienstleister
      eingesetzt. Etwaige eingesetzte Fremdunternehmen und externen Dienstleister werden im Rahmen von Art. 28 Abs. 1
      DSGVO als Auftragsverarbeiter sorgf&auml;ltig ausgew&auml;hlt, regelm&auml;&szlig;ig &uuml;berpr&uuml;ft und
      vertraglich verpflichtet, s&auml;mtliche personenbezogenen Daten ausschlie&szlig;lich entsprechend unserer
      Weisungen zu verarbeiten.</span
    >
  </p>
  <p class="c7 c61"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="8">
    <li class="c65 c11 li-bullet-0"><span class="c25">Daten&uuml;bermittlung in Drittl&auml;nder </span></li>
  </ol>
  <p class="c8 c7"><span class="c6"></span></p>
  <p class="c63">
    <span class="c4"
      >Soweit nicht anders angegeben, findet eine Daten&uuml;bermittlung an Stellen in Staaten au&szlig;erhalb der
      Europ&auml;ischen Union (sogenannte Drittstaaten) nur statt, soweit</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <ul class="c1 lst-kix_list_1-0 start">
    <li class="c0 c67 li-bullet-4"><span class="c4">es zur Ausf&uuml;hrung Ihrer Auftr&auml;ge erforderlich ist (z. B. Zahlungsauftr&auml;ge),</span></li>
    <li class="c10 c67 li-bullet-3"><span class="c4">es zur Produktverbesserung dient,</span></li>
    <li class="c10 c67 li-bullet-4"><span class="c4">ein angemessenes Datenschutzniveau sichergestellt ist oder</span></li>
    <li class="c10 c67 li-bullet-3"><span class="c4">Sie uns Ihre Einwilligung erteilt haben.</span></li>
  </ul>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="9">
    <li class="c0 c11 li-bullet-0"><span class="c25">Datensicherheit</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="22">
    <li class="c0 c18 li-bullet-9"><span class="c6">Allgemeine technisch organisatorische Ma&szlig;nahmen</span></li>
  </ol>
  <p class="c30 c38">
    <span class="c4"
      >K&auml;rcher hat eine Vielzahl von Sicherheitsma&szlig;nahmen ergriffen, um personenbezogene Informationen im
      angemessenen Umfang und ad&auml;quat zu sch&uuml;tzen. Alle bei K&auml;rcher gespeicherten Daten werden durch
      physische und technische Ma&szlig;nahmen sowie Verfahrensma&szlig;nahmen gesch&uuml;tzt, die den Zugang zu den
      Informationen auf speziell autorisierte Personen in &Uuml;bereinstimmung mit dieser Datenschutzerkl&auml;rung
      einschr&auml;nken.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="23">
    <li class="c8 c18 li-bullet-7"><span class="c6">Sichere Daten&uuml;bertragung</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Die &Uuml;bertragung Ihrer pers&ouml;nlichen Daten im Rahmen eines Bestellvorgangs erfolgt verschl&uuml;sselt
      unter &nbsp;Nutzung &nbsp;des &nbsp;Industrie-Standards &nbsp;Secure-Socket-Layer
      &nbsp;(&bdquo;SSL&ldquo;)-Technologie, SSL-Verschl&uuml;sselung Version oder vergleichbaren Technologien.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="24">
    <li class="c0 c18 li-bullet-3"><span class="c6">Passw&ouml;rter</span></li>
  </ol>
  <p class="c51 c38 c75">
    <span class="c4"
      >Ihr Passwort f&uuml;r den Zugang zu unserer Plattform sollten Sie niemals an Dritte weitergeben und es auch
      regelm&auml;&szlig;ig &auml;ndern. Wenn Sie Ihr Nutzerkonto verlassen wollen, sollten Sie das Logout</span
    >
  </p>
  <p class="c38 c45">
    <span class="c4"
      >bet&auml;tigen und Ihren Browser schlie&szlig;en, um zu vermeiden, dass jemand unberechtigt Zugang dazu
      erlangt.</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="10">
    <li class="c0 c11 li-bullet-0"><span class="c25">Rechte der betroffenen Person</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="25">
    <li class="c8 c18 li-bullet-7"><span class="c6">Recht auf Auskunft</span></li>
  </ol>
  <p class="c21">
    <span class="c4"
      >Sie k&ouml;nnen Auskunft nach Art. 15 DSGVO &uuml;ber Ihre personenbezogenen Daten verlangen, die wir
      verarbeiten.</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="26">
    <li class="c0 c18 li-bullet-3"><span class="c6">Recht auf Widerspruch:</span></li>
  </ol>
  <p class="c19">
    <span class="c4">Sie haben ein Recht auf Widerspruch aus besonderen Gr&uuml;nden (siehe unter Ziff.8).</span>
  </p>
  <p class="c29 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="27">
    <li class="c0 c18 li-bullet-3"><span class="c6">Recht auf Berichtigung:</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, k&ouml;nnen Sie nach Art. 16 DSGVO eine
      Berichtigung verlangen. Sollten Ihre Daten unvollst&auml;ndig sein, k&ouml;nnen Sie eine Vervollst&auml;ndigung
      verlangen.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="28">
    <li class="c0 c18 li-bullet-7"><span class="c6">Recht auf L&ouml;schung:</span></li>
  </ol>
  <p class="c51 c38">
    <span class="c4"
      >Sie k&ouml;nnen nach Art. 17 DSGVO die L&ouml;schung Ihrer personenbezogenen Daten verlangen.</span
    >
  </p>
  <p class="c7 c29"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="29">
    <li class="c0 c18 li-bullet-3"><span class="c6">Recht auf Einschr&auml;nkung der Verarbeitung:</span></li>
  </ol>
  <p class="c37">
    <span class="c4"
      >Sie haben nach Art. 18 DSGVO das Recht, eine Einschr&auml;nkung der Verarbeitung Ihrer personen-bezogenen Daten
      zu verlangen.</span
    >
  </p>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="30">
    <li class="c8 c18 li-bullet-4"><span class="c6">Recht auf Beschwerde:</span></li>
  </ol>
  <p class="c19"><span class="c4">Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen</span></p>
  <p class="c38 c72">
    <span class="c4"
      >Daten gegen Datenschutzrecht verst&ouml;&szlig;t, haben Sie nach Art. 77 Abs. 1 DSGVO das Recht, sich bei einer
      Datenschutzaufsichtsbeh&ouml;rde eigener Wahl oder der f&uuml;r die Verantwortliche Stelle zust&auml;ndigen
      Datenschutzaufsichtsbeh&ouml;rde zu beschweren.</span
    >
  </p>
  <p class="c7 c9"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="31">
    <li class="c0 c18 li-bullet-3"><span class="c6">Recht auf Daten&uuml;bertragbarkeit</span></li>
  </ol>
  <p class="c38 c64">
    <span class="c4"
      >F&uuml;r den Fall, dass die Voraussetzungen des Art. 20 Abs. 1 DSGVO vorliegen, steht Ihnen das Recht zu, sich
      Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten,
      an sich oder an Dritte aush&auml;ndigen zu lassen.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="32">
    <li class="c0 c55 c38 li-bullet-6">
      <span class="c6">Recht auf Widerspruch gem&auml;&szlig; Art. 21 Abs. 1 DSGVO:</span>
    </li>
  </ol>
  <p class="c38 c51">
    <span class="c4">Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben,</span>
  </p>
  <p class="c38 c54">
    <span class="c4"
      >jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten, die aufgrund von Artikel 6 Abs.</span
    >
  </p>
  <p class="c54 c38" id="h.gjdgxs">
    <span class="c4"
      >1 lit. f DSGVO erfolgt, Widerspruch einzulegen. Der Verantwortliche verarbeitet die personen-bezogenen Daten
      dann nicht mehr, es sei denn, er kann zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
      nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die
      Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="33">
    <li class="c0 c55 c38 li-bullet-6">
      <span class="c36 c42"
        >Wenn Sie nicht w&uuml;nschen, dass wir die technischen Daten Ihrer Maschinen mit verbauter K&auml;rcher
        IoT-Telematikeinheit erfassen und speichern, kontaktieren Sie uns bitte unter info@fleet.kaercher.com.</span
      >
    </li>
  </ol>
  <p class="c7 c33"><span class="c4"></span></p>
  <p class="c0 c7"><span class="c4"></span></p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="11">
    <li class="c8 c11 li-bullet-0"><span class="c25">&Auml;nderungen dieser Datenschutzerkl&auml;rung</span></li>
  </ol>
  <p class="c10 c7"><span class="c6"></span></p>
  <p class="c16">
    <span class="c4"
      >Wir halten diese Datenschutzerkl&auml;rung immer auf dem neuesten Stand. Deshalb behalten wir uns vor, sie von
      Zeit zu Zeit zu aktualisieren und anzupassen und &Auml;nderungen bei der Verarbeitung oder Nutzung Ihrer Daten
      nachzupflegen. Die aktuelle Fassung der Datenschutzerkl&auml;rung ist stets unter</span
    >
  </p>
  <p class="c69 c70">
    <span class="c4">&bdquo;Datenschutzerkl&auml;rung &ldquo; innerhalb der Plattform abrufbar.</span>
  </p>
</html>
`;
