import { Action } from 'redux';
import { Reminder } from '../../interfaces/Reminder.types';

export interface IShowDeleteReminderModalOptions {
  reminderToDelete: Reminder;
}
export type IShowDeleteReminderModalAction = Action<typeof ReminderModalsActions.SHOW_DELETE_REMINDER_MODAL> & {
  payload: IShowDeleteReminderModalOptions;
};
export type IHideDeleteReminderModalAction = Action<typeof ReminderModalsActions.HIDE_DELETE_REMINDER_MODAL>;

export type IDeleteReminderModalIsLoadingAction = Action<typeof ReminderModalsActions.DELETE_REMINDER_MODAL_IS_LOADING>;
export type IDeleteReminderModalIsNotLoadingAction = Action<
  typeof ReminderModalsActions.DELETE_REMINDER_MODAL_IS_NOT_LOADING
>;

export type IShowReminderCreateModalAction = Action<typeof ReminderModalsActions.SHOW_REMINDER_CREATE_MODAL>;
export type IHideReminderCreateModalAction = Action<typeof ReminderModalsActions.HIDE_REMINDER_CREATE_MODAL>;

export type ICreateReminderModalIsLoadingAction = Action<typeof ReminderModalsActions.CREATE_REMINDER_MODAL_IS_LOADING>;
export type ICreateReminderModalIsNotLoadingAction = Action<
  typeof ReminderModalsActions.CREATE_REMINDER_MODAL_IS_NOT_LOADING
>;

export class ReminderModalsActions {
  public static SHOW_DELETE_REMINDER_MODAL = 'REMINDER_MODALS::SHOW_DELETE_REMINDER_MODAL';
  public static HIDE_DELETE_REMINDER_MODAL = 'REMINDER_MODALS::HIDE_DELETE_REMINDER_MODAL';

  public static DELETE_REMINDER_MODAL_IS_LOADING = 'REMINDER_MODALS::DELETE_REMINDER_MODAL_IS_LOADING';
  public static DELETE_REMINDER_MODAL_IS_NOT_LOADING = 'REMINDER_MODALS::DELETE_REMINDER_MODAL_IS_NOT_LOADING';

  public static SHOW_REMINDER_CREATE_MODAL = 'REMINDER_MODALS::SHOW_REMINDER_CREATE_MODAL';
  public static HIDE_REMINDER_CREATE_MODAL = 'REMINDER_MODALS::HIDE_REMINDER_CREATE_MODAL';

  public static CREATE_REMINDER_MODAL_IS_LOADING = 'REMINDER_MODALS::CREATE_REMINDER_MODAL_IS_LOADING';
  public static CREATE_REMINDER_MODAL_IS_NOT_LOADING = 'REMINDER_MODALS::CREATE_REMINDER_MODAL_IS_NOT_LOADING';

  public static showDeleteReminderModal({
    reminderToDelete,
  }: IShowDeleteReminderModalOptions): IShowDeleteReminderModalAction {
    return {
      type: ReminderModalsActions.SHOW_DELETE_REMINDER_MODAL,
      payload: {
        reminderToDelete,
      },
    };
  }

  public static hideDeleteReminderModal(): IHideDeleteReminderModalAction {
    return {
      type: ReminderModalsActions.HIDE_DELETE_REMINDER_MODAL,
    };
  }

  public static deleteReminderModalIsLoading(): IDeleteReminderModalIsLoadingAction {
    return {
      type: ReminderModalsActions.DELETE_REMINDER_MODAL_IS_LOADING,
    };
  }

  public static deleteReminderModalIsNotLoading(): IDeleteReminderModalIsNotLoadingAction {
    return {
      type: ReminderModalsActions.DELETE_REMINDER_MODAL_IS_NOT_LOADING,
    };
  }

  public static showReminderCreateModal(): IShowReminderCreateModalAction {
    return {
      type: ReminderModalsActions.SHOW_REMINDER_CREATE_MODAL,
    };
  }

  public static hideReminderCreateModal(): IHideReminderCreateModalAction {
    return {
      type: ReminderModalsActions.HIDE_REMINDER_CREATE_MODAL,
    };
  }

  public static createReminderModalIsLoading(): ICreateReminderModalIsLoadingAction {
    return {
      type: ReminderModalsActions.CREATE_REMINDER_MODAL_IS_LOADING,
    };
  }

  public static createReminderModalIsNotLoading(): ICreateReminderModalIsNotLoadingAction {
    return {
      type: ReminderModalsActions.CREATE_REMINDER_MODAL_IS_NOT_LOADING,
    };
  }
}
