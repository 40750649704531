import { createSlice } from '@reduxjs/toolkit';
import {
  ICreateNoteErrorAction,
  ICreateNoteRequestAction,
  ICreateNoteSuccessAction,
  IDeleteNoteErrorAction,
  IDeleteNoteRequestAction,
  IDeleteNoteSuccessAction,
  IUpdateNoteErrorAction,
  IUpdateNoteRequestAction,
  IUpdateNoteSuccessAction,
} from './noteActions.types';
import { MachineNote } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface NoteState {
  data: Optional<MachineNote> | boolean;
  isLoading: boolean;
}

export const initialState: NoteState = {
  data: null,
  isLoading: false,
};

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    createNoteRequest: (state, _action: ICreateNoteRequestAction) => {
      state.isLoading = true;
      return state;
    },
    createNoteSuccess: (state, action: ICreateNoteSuccessAction) => {
      state.isLoading = false;
      state.data = action.payload?.machineNoteCreate.data;
      return state;
    },
    createNoteError: (state, _action: ICreateNoteErrorAction) => {
      state.isLoading = false;
      return state;
    },
    updateNoteRequest: (state, _action: IUpdateNoteRequestAction) => {
      state.isLoading = true;
      return state;
    },
    updateNoteSuccess: (state, action: IUpdateNoteSuccessAction) => {
      state.isLoading = false;
      state.data = action.payload?.machineNoteUpdate.data;
      return state;
    },
    updateNoteError: (state, _action: IUpdateNoteErrorAction) => {
      state.isLoading = false;
      return state;
    },
    deleteNoteRequest: (state, _action: IDeleteNoteRequestAction) => {
      state.isLoading = true;
      return state;
    },
    deleteNoteSuccess: (state, action: IDeleteNoteSuccessAction) => {
      state.isLoading = false;
      state.data = action.payload?.machineNoteDelete.data;
      return state;
    },
    deleteNoteError: (state, _action: IDeleteNoteErrorAction) => {
      state.isLoading = false;
      return state;
    },
  },
});

export const NoteActions = noteSlice.actions;
export const noteReducer = noteSlice.reducer;
