import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form } from 'antd';
import { useCallback } from 'react';
import { Machine } from '../../../../machine-inventory/interfaces/Machine.types';
import { NoteModalsActions } from '../../state/noteModalsSlice';
import { selectAddNoteModalIsLoading, selectAddNoteModalIsVisible } from '../../state/noteModalsSelectors';
import { StyledAddNoteModal } from './AddNoteModal.styles';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { TextArea } from 'lib/components/TextArea/TextArea';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { NoteActions } from 'app/modules/note/state/noteSlice';

export interface AddNoteModalProps {
  machine: Machine;
}

export const AddNoteModal = ({ machine }: AddNoteModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [noteFormInstance] = Form.useForm();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const isVisible = useSelector(selectAddNoteModalIsVisible);
  const isLoading = useSelector(selectAddNoteModalIsLoading);

  const handleOk = useCallback(() => {
    noteFormInstance.submit();
  }, [noteFormInstance]);

  const handleCancel = useCallback(() => {
    dispatch(NoteModalsActions.hideAddNoteModal());
    noteFormInstance.resetFields();
  }, [dispatch, noteFormInstance]);

  const onFinish = useCallback(() => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_DETAILS_NOTES_ADD,
    });
    if (!machine) return;

    dispatch(
      NoteActions.createNoteRequest({
        machineId: machine.id,
        description: noteFormInstance.getFieldValue('description'),
      })
    );
  }, [analyticsLinkActivated, machine, dispatch, noteFormInstance]);

  useAnalyticsSetForm({
    name: AnalyticsForm.ADD_NOTE,
    fields: {
      machineId: machine?.id,
      description: noteFormInstance.getFieldValue('description'),
    },
    isVisible,
  });

  return (
    <StyledAddNoteModal
      className="note-add-modal"
      title={t('note.addNote.title')}
      centered
      open={isVisible}
      destroyOnClose={true}
      closable={false}
      footer={[
        <LegacySecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('note.button.saveNote')}
        </LegacyPrimaryButton>,
      ]}
      afterClose={(): void => noteFormInstance.resetFields()}
    >
      <Form form={noteFormInstance} name="note-add" layout="vertical" autoComplete="off" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('note.form.label.description')}
              className="note-add-modal__input-group"
              required
              rules={[{ required: true, message: t('note.form.errors.descriptionRequired') }]}
            >
              <TextArea className="note-add-modal__text-area" placeholder={t('note.form.placeholder.description')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledAddNoteModal>
  );
};
