import isNil from 'lodash-es/isNil';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { ThemeConstants } from '../../../../../config/theme';
import { useAnalyticsSetFilter } from '../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import { useAnalyticsSetPageInfo } from '../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { FeatureFlagSelectors } from '../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { TotalMachineOperatingHoursDatum } from '../../../cleaning/widgets/fleet-dashboard/components/MachineOperatingHoursDashboardChart/interfaces/MachineOperatingHoursDashboardChart.types';
import { HighPriorityNotifications } from '../../../notification/widgets/priority-notifications/components/HighPriorityNotifications/HighPriorityNotifications';
import { HighPriorityNotificationsMap } from '../../../notification/widgets/priority-notifications/components/HighPriorityNotificationsMap/HighPriorityNotificationsMap';
import { PriorityNotificationsActions } from '../../../notification/widgets/priority-notifications/state/priorityNotificationsActions';
import { PriorityNotificationsSelectors } from '../../../notification/widgets/priority-notifications/state/PriorityNotificationsSelectors';
import { FleetOperatingHoursChart } from '../../../cleaning/widgets/fleet-dashboard/components/FleetOperatingHoursChart/FleetOperatingHoursChart';
import { StyledOverview } from './Overview.styles';
import {
  MachineClassification,
  Severity,
  SortOrders,
  Type,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DEFAULT_PAGE_VALUE, OpenSearch } from 'config/constants';
import { SitePerformanceChart } from 'app/modules/site-management/widgets/fleet-dashboard/components/SitePerformanceChart/SitePerformanceChart';
import { SitePerformanceChartUtils } from 'app/modules/site-management/utils/SitePerformanceChartUtils';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { SiteWidgetsFleetDashboardActions } from 'app/modules/site-management/widgets/fleet-dashboard/state/siteWidgetsFleetDashboardActions';
import { SiteWidgetsFleetDashboardSelectors } from 'app/modules/site-management/widgets/fleet-dashboard/state/siteWidgetsFleetDashboardSelectors';
import { MachineOperatingHoursDashboardChart } from 'app/modules/cleaning/widgets/fleet-dashboard/components/MachineOperatingHoursDashboardChart/MachineOperatingHoursDashboardChart';
import { CleaningWidgetsFleetDashboardActions } from 'app/modules/cleaning/widgets/fleet-dashboard/state/state/cleaningWidgetsFleetDashboardActions';
import { CleaningWidgetsFleetDashboardSelectors } from 'app/modules/cleaning/widgets/fleet-dashboard/state/state/cleaningWidgetsFleetDashboardSelectors';
import { FleetOperatingTimeSelectors } from 'app/modules/cleaning/widgets/fleet-dashboard/state/FleetOperatingHoursChart/fleetOperatingTimeSelectors';
import { FleetOperatingTimeActions } from 'app/modules/cleaning/widgets/fleet-dashboard/state/FleetOperatingHoursChart/fleetOperatingTimeActions';
import {
  AnalyticsOverviewFilter,
  IAnalyticsFilter,
} from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { CleaningReportRequestDateRangeRestrictor } from 'app/modules/cleaning/CleaningReportRequestDateRangeRestrictor';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';

const CONSTANTS = {
  notifications: {
    pageSize: 4,
    sortField: 'timestamp',
    sortOrder: SortOrders.Desc,
  },
};

export const Overview = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [period, setPeriod] = useState<{ startAt: Date; endAt: Date }>({
    startAt: OperatingHoursChartUtils.prepareStartDate(START_DATE),
    endAt: OperatingHoursChartUtils.prepareEndDate(END_DATE),
  });

  const notifications = useSelector(PriorityNotificationsSelectors.selectData) || [];
  const isLoading = !!useSelector(PriorityNotificationsSelectors.selectIsLoading);
  const paginationTokens = useSelector(PriorityNotificationsSelectors.selectPaginationTokens);

  const sitesData = useSelector(SiteWidgetsFleetDashboardSelectors.selectData) || [];
  const isSitesLoading = !!useSelector(SiteWidgetsFleetDashboardSelectors.selectIsLoading);
  const sitePerformanceChartData = SitePerformanceChartUtils.convertSitesToChartData(sitesData);
  const fleetOperatingHoursChartData = useSelector(FleetOperatingTimeSelectors.selectData);
  const isFleetOperatingHoursChartLoading = !!useSelector(FleetOperatingTimeSelectors.selectIsLoading);

  const machineOperatingHoursDashboardChartData: TotalMachineOperatingHoursDatum[] =
    useSelector(CleaningWidgetsFleetDashboardSelectors.selectData) || [];
  const isMachineOperatingTimeLoading = !!useSelector(CleaningWidgetsFleetDashboardSelectors.selectIsLoading);

  const isEnablePriorityNotifications = features.PRIORITY_NOTIFICATIONS;

  const getActiveFiltersCallback = useCallback((): IAnalyticsFilter[] => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(period.startAt) && !isNil(period.endAt)) {
      activeFilters.push(AnalyticsOverviewFilter.DATE_RANGE);
    }

    return activeFilters;
  }, [period.endAt, period.startAt]);

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });
  useAnalyticsSetPageInfo({});

  useEffect(() => {
    if (period.startAt && period.endAt) {
      const operatingTimePeriodFilter = {
        startAt: dayjs(period.startAt).toISOString(),
        endAt: dayjs(period.endAt).toISOString(),
      };

      dispatch(
        SiteWidgetsFleetDashboardActions.getSiteListWithOperatingTimeRequest({
          period: operatingTimePeriodFilter,
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );

      dispatch(
        CleaningWidgetsFleetDashboardActions.getCleaningListWithOperatingTimeRequest({
          filter: {
            classifications: [MachineClassification.Gcd, MachineClassification.Robot],
          },
          period: operatingTimePeriodFilter,
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );

      dispatch(
        FleetOperatingTimeActions.getFleetOperatingTimeRequest({
          filter: {
            period: operatingTimePeriodFilter,
          },
        })
      );
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (isEnablePriorityNotifications) {
      dispatch(
        PriorityNotificationsActions.getPriorityNotificationsRequest({
          paginationOptions: {
            limit: CONSTANTS.notifications.pageSize,
            paginationToken: paginationTokens[DEFAULT_PAGE_VALUE] || '',
          },
          filter: {
            severities: [Severity.Major, Severity.Critical],
            type: Type.Alert,
          },
          sortOptions: {
            field: CONSTANTS.notifications.sortField,
            order: CONSTANTS.notifications.sortOrder,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(
    () => (): void => {
      dispatch(PriorityNotificationsActions.resetState());
      dispatch(CleaningWidgetsFleetDashboardActions.resetState());
      dispatch(FleetOperatingTimeActions.resetState());
    },
    [dispatch]
  );

  const onPeriodChange = (values: { dateRangeLocal: [Dayjs | null, Dayjs | null] | null }): void => {
    const { dateRangeLocal: dates } = values;

    if (dates && dates[0] && dates[1]) {
      setPeriod({
        startAt: OperatingHoursChartUtils.prepareStartDate(dates[0].utc().toDate()),
        endAt: OperatingHoursChartUtils.prepareEndDate(dates[1].utc().toDate()),
      });
    }
  };

  return (
    <StyledOverview>
      <div className="overview__header">
        <div className="overview__header-content">
          <div className="overview__container--wide">
            <h1 className="overview__title">{t('overview.fleetDashboard.dashboard')}</h1>
          </div>
        </div>
      </div>
      <div className="overview__body">
        <div className="overview__body-content">
          <div className="overview__container--wide">
            {isEnablePriorityNotifications && (
              <h1 className="overview__sub-header">{t('overview.highPriorityNotifications.title')}</h1>
            )}
            <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]} className="overview__info">
              {isEnablePriorityNotifications && (
                <Col xs={24} sm={24} md={24} lg={12}>
                  <HighPriorityNotifications notifications={notifications} isLoading={isLoading} />
                </Col>
              )}

              {isEnablePriorityNotifications && (
                <Col xs={24} sm={24} md={24} lg={12}>
                  <HighPriorityNotificationsMap notifications={notifications} isLoading={isLoading} />
                </Col>
              )}

              <Col xs={24} sm={24} md={24} lg={24}>
                <Row>
                  <Form
                    form={form}
                    className="overview__filter-form"
                    name="overview__filter-form"
                    layout="horizontal"
                    onFinish={onPeriodChange}
                    onValuesChange={(): void => form.submit()}
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    initialValues={{
                      dateRangeLocal: [dayjs(period.startAt), dayjs(period.endAt)],
                    }}
                  >
                    <Form.Item
                      name="dateRangeLocal"
                      required
                      rules={[
                        {
                          required: true,
                          message: t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeRequired'),
                        },
                        {
                          validator: CleaningReportRequestDateRangeRestrictor.validate,
                        },
                      ]}
                    >
                      <RangePicker disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate} />
                    </Form.Item>
                  </Form>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Spin spinning={isFleetOperatingHoursChartLoading}>
                  <FleetOperatingHoursChart
                    actualTotalOperatingTimeS={fleetOperatingHoursChartData?.actualTotalOperatingTimeS || 0}
                    plannedTotalOperatingTimeS={fleetOperatingHoursChartData?.plannedTotalOperatingTimeS || 0}
                  />
                </Spin>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Spin spinning={isMachineOperatingTimeLoading}>
                  <MachineOperatingHoursDashboardChart data={machineOperatingHoursDashboardChartData} />
                </Spin>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24}>
                <Spin spinning={isSitesLoading}>
                  <SitePerformanceChart
                    data={sitePerformanceChartData}
                    startDate={period.startAt.toISOString() ?? ''}
                    endDate={period.endAt.toISOString() ?? ''}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </StyledOverview>
  );
};
