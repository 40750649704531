import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  selectDeleteNoteModalIsLoading,
  selectDeleteNoteModalIsVisible,
  selectDeleteNoteModalNoteToDelete,
} from '../../state/noteModalsSelectors';
import { NoteModalsActions } from '../../state/noteModalsSlice';
import { StyledDeleteNoteModal } from './DeleteNoteModal.styles';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { NoteActions } from 'app/modules/note/state/noteSlice';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';

export const DeleteNoteModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const isVisible = useSelector(selectDeleteNoteModalIsVisible);
  const isLoading = useSelector(selectDeleteNoteModalIsLoading);
  const note = useSelector(selectDeleteNoteModalNoteToDelete);

  const handleOk = useCallback(() => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_DETAILS_NOTES_DELETE,
    });

    if (!note) return;

    dispatch(
      NoteActions.deleteNoteRequest({
        id: note.id,
        machineId: note.machineId,
      })
    );
  }, [analyticsLinkActivated, dispatch, note]);

  const handleCancel = useCallback(() => {
    dispatch(NoteModalsActions.hideDeleteNoteModal());
  }, [dispatch]);

  useAnalyticsSetForm({
    name: AnalyticsForm.DELETE_NOTE,
    fields: {
      machineId: note?.machineId,
      id: note?.id,
    },
    isVisible,
  });

  return (
    <StyledDeleteNoteModal
      className="note-delete-modal"
      title={t('note.deleteNote.title')}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <LegacySecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('note.button.deleteNote')}
        </LegacyPrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('note.deleteNote.modalBody')}</p>
      <p className="modal__description">{note?.description}</p>
    </StyledDeleteNoteModal>
  );
};
