import React from 'react';
import { get, isNil, uniqBy } from 'lodash-es';
import { StyledHighPriorityNotificationsMap } from './HighPriorityNotificationsMap.styles';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineMap } from 'app/modules/machine-inventory/components/MachineMap/MachineMap';

const hasLocationData = (notification: Notification): boolean =>
  !isNil(get(notification, 'machine.location.latitude')) && !isNil(get(notification, 'machine.location.longitude'));

export interface HighPriorityNotificationsMapProps {
  notifications: Notification[];
  isLoading: boolean;
}

export const HighPriorityNotificationsMap: React.FC<HighPriorityNotificationsMapProps> = ({
  notifications,
  isLoading,
}): JSX.Element => {
  const uniqueNotificationsMachineWithLocation = uniqBy(notifications.filter(hasLocationData), 'machine.id');
  const machines = uniqueNotificationsMachineWithLocation.map(({ machine }) => machine);
  return (
    <StyledHighPriorityNotificationsMap className="high-priority-notifications-map">
      <MachineMap machines={machines} isLoading={isLoading} />
    </StyledHighPriorityNotificationsMap>
  );
};
