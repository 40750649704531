import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledNotificationListSubscriptionDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: ${(props): string => props.theme.spacing.lg};
  }

  .notification-subscription-drawer__header {
    flex-grow: 0;
  }

  .notification-subscription-drawer__title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    text-transform: uppercase;
  }

  .notification-subscription-drawer__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${(props): string => props.theme.spacing.xs};
    overflow: auto;

    .notification-subscription-drawer__input-group {
      margin-bottom: ${(props): string => props.theme.spacing.base};
    }

    .notification-subscription-drawer__input-group--select-all {
      margin-bottom: ${(props): string => props.theme.spacing.lg};
    }

    .notification-subscription-drawer__description {
      ${(props): string => props.theme.fontStyles.redesign.base.normal};
    }

    .ant-form-item-control-input {
      min-height: unset;
    }

    .notification-subscription-drawer__checkbox {
      ${(props): string => props.theme.fontStyles.redesign.base.normal};
    }
  }

  .notification-subscription-drawer__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
  }

  .notification-subscription-drawer__close-button,
  .notification-subscription-drawer__save-button {
    &.ant-btn.button-base.button__size-m {
      ${(props): string => props.theme.fontStyles.redesign.base.delete};
    }
  }
`;
