import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { NoteUpdateResponse, NoteCreateResponse, NoteDeleteResponse } from '../interfaces/Note.types';
import { NoteModalsActions } from '../modals/state/noteModalsSlice';
import {
  IAddNoteModalIsLoadingAction,
  IAddNoteModalIsNotLoadingAction,
  IDeleteNoteModalIsNotLoadingAction,
  IDeleteNoteModalIsLoadingAction,
  IEditNoteModalIsLoadingAction,
  IEditNoteModalIsNotLoadingAction,
  IHideAddNoteModalAction,
  IHideDeleteNoteModalAction,
  IHideEditNoteModalAction,
} from '../modals/state/noteModalsActions.types';
import { NoteActions } from './noteSlice';
import {
  ICreateNoteErrorAction,
  ICreateNoteRequestAction,
  ICreateNoteSuccessAction,
  IDeleteNoteErrorAction,
  IDeleteNoteRequestAction,
  IDeleteNoteSuccessAction,
  IUpdateNoteErrorAction,
  IUpdateNoteRequestAction,
  IUpdateNoteSuccessAction,
} from './noteActions.types';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetMachineNotesRequestAction,
  MachineDetailsActions,
} from 'app/modules/machine-inventory/machine-details/state/machineDetailsActions';
import { MACHINE_NOTE_LIST_PAGE_SIZE } from 'app/modules/machine-inventory/utils/constants';

export function* createNoteSaga(
  action: ICreateNoteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NoteCreateResponse> | void>
  | PutEffect<IHideAddNoteModalAction>
  | PutEffect<IAddNoteModalIsLoadingAction>
  | PutEffect<IAddNoteModalIsNotLoadingAction>
  | PutEffect<ICreateNoteSuccessAction>
  | PutEffect<ICreateNoteErrorAction>
  | PutEffect<IGetMachineNotesRequestAction>,
  void,
  IDependencies
> {
  const { noteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { machineId, description } = action.payload;

    yield* put(NoteModalsActions.addNoteModalIsLoading());

    const response = yield* call(noteService.create, { machineId, description });

    if (response?.machineNoteCreate?.data) {
      yield* put(NoteActions.createNoteSuccess(response));
      yield* put(
        MachineDetailsActions.getMachineNotesRequest({
          machineId,
          paginationOptions: {
            limit: MACHINE_NOTE_LIST_PAGE_SIZE,
          },
          append: false,
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* put(
      NoteActions.createNoteError({
        error,
      })
    );
  } finally {
    yield* put(NoteModalsActions.addNoteModalIsNotLoading());
    yield* put(NoteModalsActions.hideAddNoteModal());
  }
}

export function* updateNoteSaga(
  action: IUpdateNoteRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<NoteUpdateResponse>>
  | PutEffect<IHideEditNoteModalAction>
  | PutEffect<IEditNoteModalIsLoadingAction>
  | PutEffect<IEditNoteModalIsNotLoadingAction>
  | PutEffect<IUpdateNoteSuccessAction>
  | PutEffect<IUpdateNoteErrorAction>
  | PutEffect<IGetMachineNotesRequestAction>,
  void,
  IDependencies
> {
  const { noteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { id, machineId, description } = action.payload;

    yield* put(NoteModalsActions.editNoteModalIsLoading());

    const response = yield* call(noteService.update, { id, machineId, description });

    if (response?.machineNoteUpdate?.data) {
      yield* put(NoteActions.updateNoteSuccess(response));
      yield* put(
        MachineDetailsActions.getMachineNotesRequest({
          machineId,
          paginationOptions: {
            limit: MACHINE_NOTE_LIST_PAGE_SIZE,
          },
          append: false,
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* put(
      NoteActions.updateNoteError({
        error,
      })
    );
  } finally {
    yield* put(NoteModalsActions.editNoteModalIsNotLoading());
    yield* put(NoteModalsActions.hideEditNoteModal());
  }
}

export function* deleteNoteSaga(
  action: IDeleteNoteRequestAction
): Generator<
  | GetContextEffect
  | PutEffect<IDeleteNoteModalIsLoadingAction>
  | CallEffect<Optional<NoteDeleteResponse>>
  | PutEffect<IDeleteNoteSuccessAction>
  | PutEffect<IGetMachineNotesRequestAction>
  | PutEffect<IDeleteNoteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteNoteModalAction>
  | PutEffect<IDeleteNoteErrorAction>,
  void,
  IDependencies
> {
  const { noteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { id, machineId } = action.payload;

    yield* put(NoteModalsActions.deleteNoteModalIsLoading());

    const response = yield* call(noteService.delete, { machineId, id });

    if (response?.machineNoteDelete?.data) {
      yield* put(NoteActions.deleteNoteSuccess(response));
      yield* put(
        MachineDetailsActions.getMachineNotesRequest({
          machineId,
          paginationOptions: {
            limit: MACHINE_NOTE_LIST_PAGE_SIZE,
          },
          append: false,
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* put(
      NoteActions.deleteNoteError({
        error,
      })
    );
  } finally {
    yield* put(NoteModalsActions.deleteNoteModalIsNotLoading());
    yield* put(NoteModalsActions.hideDeleteNoteModal());
  }
}

export function* noteSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(NoteActions.createNoteRequest, createNoteSaga);
  yield* takeLatest(NoteActions.updateNoteRequest, updateNoteSaga);
  yield* takeLatest(NoteActions.deleteNoteRequest, deleteNoteSaga);
}
