import styled from 'styled-components';
import { privacyPolicyStyle } from './language/de/PrivacyPolicyCss';

export const StyledPrivacyPolicy = styled.div`
  flex: auto;
  padding: 50px 100px;

  .privacy-policy__content {
    ${privacyPolicyStyle}
  }
`;
