import { Reminder } from '../../interfaces/Reminder.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class ReminderModalsSelectors {
  public static selectDeleteReminderModalReminderToDelete = (state: IState): Optional<Reminder> =>
    state.modules.reminder.modals.deleteReminder.reminderToDelete;

  public static selectIsDeleteReminderModalVisible = (state: IState): boolean =>
    state.modules.reminder.modals.deleteReminder.visible;

  public static selectIsDeleteReminderModalLoading = (state: IState): boolean =>
    state.modules.reminder.modals.deleteReminder.isLoading;

  public static selectIsReminderCreateModalVisible = (state: IState): boolean =>
    state.modules.reminder.modals.createReminder.visible;

  public static selectIsCreateReminderModalLoading = (state: IState): boolean =>
    state.modules.reminder.modals.createReminder.isLoading;
}
