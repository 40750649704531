import { produce } from 'immer';
import { AnyAction } from 'redux';
import { ReminderActions } from './reminderActions';
import { Reminder } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface ReminderState {
  data: Optional<Reminder>;
  isLoading: boolean;
}

export const initialState: ReminderState = {
  data: null,
  isLoading: false,
};

export const reminderReducer = (state = initialState, action: AnyAction): ReminderState =>
  produce(state, draft => {
    switch (action.type) {
      case ReminderActions.CREATE_REMINDER_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case ReminderActions.CREATE_REMINDER_SUCCESS: {
        const { reminderCreate } = action.payload;
        draft.data = reminderCreate?.data;
        draft.isLoading = false;
        return draft;
      }

      case ReminderActions.CREATE_REMINDER_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
