import { MachineNote } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectAddNoteModalIsVisible = (state: IState): boolean => state.modules.note.modals.addNoteModal.visible;
export const selectAddNoteModalIsLoading = (state: IState): boolean => state.modules.note.modals.addNoteModal.isLoading;

export const selectEditNoteModalIsVisible = (state: IState): boolean => state.modules.note.modals.editNoteModal.visible;
export const selectEditNoteModalIsLoading = (state: IState): boolean =>
  state.modules.note.modals.editNoteModal.isLoading;
export const selectEditNoteModalNoteToEdit = (state: IState): Optional<MachineNote> =>
  state.modules.note.modals.editNoteModal.noteToEdit;
export const selectDeleteNoteModalIsVisible = (state: IState): boolean =>
  state.modules.note.modals.deleteNoteModal.visible;
export const selectDeleteNoteModalIsLoading = (state: IState): boolean =>
  state.modules.note.modals.deleteNoteModal.isLoading;
export const selectDeleteNoteModalNoteToDelete = (state: IState): Optional<MachineNote> =>
  state.modules.note.modals.deleteNoteModal.noteToDelete;
