import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Reminder } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ReminderModalsActions } from '../../state/reminderModalsActions';
import { ReminderModalsSelectors } from '../../state/reminderModalsSelectors';
import { StyledDeleteReminderModal } from './DeleteReminderModal.styles';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { ReminderActions } from 'app/modules/reminder/state/reminderActions';
import { Optional } from 'lib/types/Optional';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';

export const DeleteReminderModal = (): JSX.Element | null => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reminder: Optional<Reminder> = useSelector(ReminderModalsSelectors.selectDeleteReminderModalReminderToDelete);
  const isVisible = useSelector(ReminderModalsSelectors.selectIsDeleteReminderModalVisible);
  const isLoading = useSelector(ReminderModalsSelectors.selectIsDeleteReminderModalLoading);

  const handleCancel = useCallback(() => dispatch(ReminderModalsActions.hideDeleteReminderModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (!reminder) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.DELETE_REMINDER,
    });

    dispatch(ReminderActions.deleteReminderRequest({ machineId: reminder.machineId, id: reminder.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsLinkActivated, dispatch, reminder?.id, reminder?.machineId]);

  useAnalyticsSetForm({
    name: AnalyticsForm.DELETE_REMINDER,
    fields: {
      reminder,
    },
    isVisible,
  });

  if (!reminder) {
    return null;
  }

  return (
    <StyledDeleteReminderModal
      className="delete-reminder-modal"
      title={t('machineDetails.reminderList.deleteReminderModal.deleteReminder')}
      open={isVisible}
      footer={[
        <LegacySecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('machineDetails.reminderList.deleteReminderModal.deleteReminder')}
        </LegacyPrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('machineDetails.reminderList.deleteReminderModal.modalBody')}</p>
      <h3 className="reminder-topic">{reminder.topic}</h3>
    </StyledDeleteReminderModal>
  );
};
