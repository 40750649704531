import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Col, Row, Form } from 'antd';
import {
  selectEditNoteModalIsLoading,
  selectEditNoteModalIsVisible,
  selectEditNoteModalNoteToEdit,
} from '../../state/noteModalsSelectors';
import { NoteModalsActions } from '../../state/noteModalsSlice';
import { StyledEditNoteModal } from './EditNoteModal.styles';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { TextArea } from 'lib/components/TextArea/TextArea';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { NoteActions } from 'app/modules/note/state/noteSlice';

export const EditNoteModal = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [noteFormInstance] = Form.useForm();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const isVisible = useSelector(selectEditNoteModalIsVisible);
  const isLoading = useSelector(selectEditNoteModalIsLoading);
  const note = useSelector(selectEditNoteModalNoteToEdit);

  const handleOk = useCallback(() => {
    noteFormInstance.submit();
  }, [noteFormInstance]);

  const handleCancel = useCallback(() => {
    dispatch(NoteModalsActions.hideEditNoteModal());
    noteFormInstance.resetFields();
  }, [dispatch, noteFormInstance]);

  const onFinish = useCallback(() => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_DETAILS_NOTES_EDIT,
    });

    if (!note) return;

    dispatch(
      NoteActions.updateNoteRequest({
        id: note.id,
        machineId: note.machineId,
        description: noteFormInstance.getFieldValue('description'),
      })
    );
  }, [analyticsLinkActivated, dispatch, note, noteFormInstance]);

  useAnalyticsSetForm({
    name: AnalyticsForm.EDIT_NOTE,
    fields: {
      id: note?.id,
      machineId: note?.machineId,
      description: noteFormInstance.getFieldValue('description'),
    },
    isVisible,
  });

  useEffect(() => {
    noteFormInstance.setFieldValue('description', note?.description);
  }, [note?.description, noteFormInstance]);

  if (!note) {
    return null;
  }

  return (
    <StyledEditNoteModal
      className="note-edit-modal"
      title={t('note.editNote.title')}
      centered
      open={isVisible}
      destroyOnClose={true}
      closable={false}
      footer={[
        <LegacySecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('note.button.saveNote')}
        </LegacyPrimaryButton>,
      ]}
    >
      <Form
        form={noteFormInstance}
        name="note-edit"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          description: note.description,
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('note.form.label.description')}
              className="note-edit-modal__input-group"
              required
              rules={[{ required: true, message: t('note.form.errors.descriptionRequired') }]}
            >
              <TextArea className="note-edit-modal__text-area" placeholder={t('note.form.placeholder.description')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledEditNoteModal>
  );
};
