import { LabeledValue as AntdSelectLabeledValue } from 'antd/es/select';
import { isArray, sortBy } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import { Empty, TablePaginationConfig, Form } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { useAnalyticsSetFilter } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import {
  AnalyticsLink,
  AnalyticsNotificationListFilter,
  IAnalyticsFilter,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import * as notificationListSelectors from '../../state/notificationListSelectors';
import { NotificationListActions } from '../../state/notificationListActions';
import * as authenticationSelectors from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { StyledNotificationList } from './NotificationList.styles';
import { getNotificationListColumns } from './columns/NotificationList.columns';
import { NotificationListSubscriptionDrawer } from './NotificationListSubscriptionDrawer/NotificationListSubscriptionDrawer';
import { Optional } from 'lib/types/Optional';
import {
  InputFilterNotificationsList,
  InputSortOptions,
  Notification,
  NotificationCategory,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { NotificationModalActions } from 'app/modules/notification/modals/state/notificationModalsActions';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { ALL_VALUE_SELECT_OPTION, InfiniteScrollConstants } from 'config/constants';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { Table } from 'lib/components/Table/Table';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { CleaningReportRequestDateRangeRestrictor } from 'app/modules/cleaning/CleaningReportRequestDateRangeRestrictor';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { Select } from 'lib/components/Select/Select';
import { SelectUtils } from 'lib/components/Select/SelectUtils';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { selectIsNotificationSubscriptionModalVisible } from 'app/modules/notification/modals/state/notificationModalsSelectors';

export const NotificationList = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef<HTMLDivElement>(null);
  const [isSitesSelectOpen, setIsSitesSelectOpen] = useState(false);
  const [isMachineNamesSelectOpen, setIsMachineNamesSelectOpen] = useState(false);
  const [isCategoriesSelectOpen, setIsCategoriesSelectOpen] = useState(false);
  const [isSeverityFilterOpen, setIsSeverityFilterOpen] = useState(false);
  const [isTypeFilterOpen, setIsTypeFilterOpen] = useState(false);
  const [period, setPeriod] = useState<{ startAt: string; endAt: string }>({
    startAt: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
    endAt: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
  });
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [form] = Form.useForm();

  const data = useSelector(notificationListSelectors.selectData) || [];
  const isLoading = !!useSelector(notificationListSelectors.selectIsLoading);

  const availableFilters = useSelector(notificationListSelectors.selectFilters);
  const areFiltersLoading = useSelector(notificationListSelectors.selectAreFiltersLoading);
  const activeSeverityFilter = useSelector(notificationListSelectors.selectActiveSeverityFilter);
  const activeTypeFilter = useSelector(notificationListSelectors.selectActiveTypeFilter);
  const activeSitesFilter = useSelector(notificationListSelectors.selectActiveSitesFilter);
  const activeMachinesFilter = useSelector(notificationListSelectors.selectActiveMachinesFilter);
  const activeCategoriesFilter = useSelector(notificationListSelectors.selectActiveCategoriesFilter);
  const isLoadingMoreData = useSelector(notificationListSelectors.selectIsLoadingMoreData);
  const nextPaginationToken = useSelector(notificationListSelectors.selectNextPaginationToken);

  const sortField = useSelector(notificationListSelectors.selectSortField) || 'timestamp';
  const sortOrder = useSelector(notificationListSelectors.selectSortOrder) || SortOrders.Desc;

  const isSortingAndFilteringEnabled = features.NOTIFICATION_FILTERS_AND_SORTING;
  const areSeverityAndTypeFiltersEnabled = features.NOTIFICATION_SEVERITY_AND_TYPE_FILTERS;
  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const isMachineReminderListEnabled = features.MACHINE_REMINDER_LIST;

  const hasAccessToRobots = useSelector(authenticationSelectors.selectHasAccessToRobots);

  const haveRobotsAvailable = isRobotIntegrationEnabled && hasAccessToRobots;

  const isNotificationSubscriptionDrawerVisible = useSelector(selectIsNotificationSubscriptionModalVisible);

  const filterCategories = useMemo(() => {
    const baseCategories: AntdSelectLabeledValue[] = [
      { value: NotificationCategory.NotifyLocationStatus, label: t(`notifications.categories.notifyLocationStatus`) },
      { value: NotificationCategory.NotifyNoWorkStart, label: t(`notifications.categories.notifyNoWorkStart`) },
      { value: NotificationCategory.NotifyPcm, label: t(`notifications.categories.notifyPCM`) },
    ];
    const robotCategories: AntdSelectLabeledValue[] = [
      { value: NotificationCategory.NotifyEmergencyStop, label: t(`notifications.categories.notifyEmergencyStop`) },
      { value: NotificationCategory.NotifyResources, label: t(`notifications.categories.notifyResources`) },
      { value: NotificationCategory.NotifyErrorOccurred, label: t(`notifications.categories.notifyErrorOccurred`) },
      {
        value: NotificationCategory.NotifyCleaningTaskInterrupted,
        label: t(`notifications.categories.notifyCleaningTaskInterrupted`),
      },
      {
        value: NotificationCategory.NotifyCleaningTaskFinished,
        label: t(`notifications.categories.notifyCleaningTaskFinished`),
      },
      {
        value: NotificationCategory.NotifyInformation,
        label: t(`notifications.categories.notifyInformation`),
      },
      {
        value: NotificationCategory.NotifyServiceNeeded,
        label: t(`notifications.categories.notifyServiceNeeded`),
      },
    ];

    let filters: AntdSelectLabeledValue[] = baseCategories;

    if (isMachineReminderListEnabled) {
      filters.push({ value: NotificationCategory.NotifyReminder, label: t(`notifications.categories.notifyReminder`) });
    }

    if (haveRobotsAvailable) {
      filters = filters.concat(robotCategories);
    }

    const sortedFilters = sortBy(filters, 'label');
    return sortedFilters;
  }, [isMachineReminderListEnabled, haveRobotsAvailable, t]);

  const getActiveFiltersCallback = useCallback((): IAnalyticsFilter[] => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(activeSitesFilter) && activeSitesFilter?.length > 0) {
      activeFilters.push(AnalyticsNotificationListFilter.ASSIGNED_SITES);
    }

    if (!isNil(activeMachinesFilter) && activeMachinesFilter?.length > 0) {
      activeFilters.push(AnalyticsNotificationListFilter.MACHINES);
    }

    return activeFilters;
  }, [activeMachinesFilter, activeSitesFilter]);

  const requestParams = (): {
    filter?: InputFilterNotificationsList;
    sortOptions?: InputSortOptions;
    lang?: string;
  } => ({
    filter: {
      severities: activeSeverityFilter && [activeSeverityFilter],
      type: activeTypeFilter,
      ...(activeCategoriesFilter?.filter(cat => cat !== ALL_VALUE_SELECT_OPTION).length && {
        categories: activeCategoriesFilter as NotificationCategory[],
      }),
      ...(isSortingAndFilteringEnabled && {
        machineIds: activeMachinesFilter?.filter(id => id !== ALL_VALUE_SELECT_OPTION).length
          ? activeMachinesFilter
          : availableFilters?.machines?.map(machine => machine.id),
      }),
      period,
    },
    sortOptions: isSortingAndFilteringEnabled
      ? {
          field: sortField,
          order: sortOrder,
        }
      : undefined,
    lang: Translations.getSupportedLanguageCode(i18n.language).toLowerCase(),
  });

  const getTotalFilterCount = (): number => {
    const sanitizedActiveSeverityFilter = activeSeverityFilter ?? [];
    const sanitizedActiveTypeFilter = activeTypeFilter ?? [];
    const sanitizedActiveSitesFilter = activeSitesFilter ?? [];
    const sanitizedActiveMachinesFilter = activeMachinesFilter ?? [];
    const sanitizedActiveCategoriesFilter = activeCategoriesFilter ?? [];

    const count = [
      sanitizedActiveSeverityFilter,
      sanitizedActiveTypeFilter,
      sanitizedActiveSitesFilter,
      sanitizedActiveMachinesFilter,
      sanitizedActiveCategoriesFilter,
    ]
      .map(value => {
        if (!isArray(value)) {
          return [value];
        }

        return value;
      })
      .reduce((acc, filterValues) => acc + (filterValues ?? []).length, 0);

    return count;
  };

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });
  useAnalyticsSetPageInfo({});

  useEffect(() => {
    // Skip duplicate notifications loading on initial state or filters are loading
    if (
      (isSortingAndFilteringEnabled &&
        typeof availableFilters?.machines === 'undefined' &&
        typeof activeMachinesFilter === 'undefined') ||
      areFiltersLoading
    ) {
      return;
    }

    dispatch(
      NotificationListActions.getNotificationListRequest({
        ...requestParams(),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    period,
    sortField,
    sortOrder,
    activeSeverityFilter,
    activeTypeFilter,
    activeMachinesFilter,
    activeCategoriesFilter,
    availableFilters?.machines,
  ]);

  const loadMore = (): void => {
    dispatch(
      NotificationListActions.getNotificationListMoreDataRequest({
        ...requestParams(),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
      })
    );
  };

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(NotificationListActions.getNotificationListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  useEffect(
    () => (): void => {
      dispatch(NotificationListActions.resetState());
      dispatch(DrawersActions.hideMachineDetailsDrawer());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  const handleSeverityFilterChange = (value: SelectValue): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.NOTIFICATIONS_SEVERITY_FILTER,
    });

    dispatch(NotificationListActions.setActiveSeverityFilter(value as Optional<string>));
  };

  const handleTypeFilterChange = (value: SelectValue): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.NOTIFICATIONS_TYPE_FILTER,
    });

    dispatch(NotificationListActions.setActiveTypeFilter(value as Optional<string>));
  };

  const handleCategoriesFilterChange = (values: SelectValue): void => {
    const catValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.NOTIFICATIONS_CATEGORIES_FILTER,
    });

    dispatch(NotificationListActions.setActiveCategoriesFilter(catValues));
  };

  const handleSitesFilterChange = (values: SelectValue): void => {
    const siteValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.NOTIFICATIONS_ASSIGNED_SITE_FILTER,
    });

    dispatch(NotificationListActions.setActiveSitesFilter(siteValues));
    dispatch(NotificationListActions.setActiveMachinesFilter([]));
    dispatch(
      NotificationListActions.getMachineListFilterRequest({
        filter: {
          siteIds: siteValues.filter(value => value !== ALL_VALUE_SELECT_OPTION),
        },
      })
    );
  };

  const handleMachineNamesFilterChange = (values: SelectValue): void => {
    const machineValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.NOTIFICATIONS_MACHINES_FILTER,
    });

    dispatch(NotificationListActions.setActiveMachinesFilter(machineValues));
  };

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Notification> | SorterResult<Notification>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    const sorterOrder = sorter.order === 'descend' ? SortOrders.Desc : SortOrders.Asc;
    // Handle for field as array like: ['machine', 'name'] => machine.name
    const sorterField = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;

    dispatch(NotificationListActions.setActiveSortField(sorterField as Optional<string>));
    dispatch(NotificationListActions.setActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const showNotificationSubscriptionModal = (): void => {
    dispatch(NotificationModalActions.showNotificationSubscriptionModal());
  };

  const goToMachineDetails = (machineId: string): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_OPEN_DETAILS,
    });

    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId }));
  };

  const handleClickSiteName = (siteId?: string): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.SITE_LIST_OPEN_DETAILS,
    });
    if (!siteId) return;

    dispatch(DrawersActions.showSiteDetailsDrawer({ siteId }));
  };

  const tableColumns = getNotificationListColumns({
    t,
    i18n,
    isSortingAndFilteringEnabled,
    haveRobotsAvailable,
    goToMachineDetails,
    handleClickSiteName,
  });

  const onPeriodChange = (values: { dateRangeLocal: [Dayjs | null, Dayjs | null] | null }): void => {
    const { dateRangeLocal: dates } = values;
    if (dates && dates[0] && dates[1]) {
      setPeriod({
        startAt: OperatingHoursChartUtils.prepareStartDate(dates[0]?.utc().toDate()).toISOString(),
        endAt: OperatingHoursChartUtils.prepareEndDate(dates[1]?.utc().toDate()).toISOString(),
      });
    }
  };

  return (
    <StyledNotificationList className="notification-list">
      <div className="notification-list__header">
        <div className="notification-list__header-content">
          <div className="notification-list__container--fluid">
            <div className="notification-list__page-info">
              <h1 className="notification-list__title">{t('notificationList.title')}</h1>
              <SecondaryButton
                size="s"
                className="notification-list__subscription-button"
                onClick={showNotificationSubscriptionModal}
              >
                {t('notificationList.notificationSubscription.title')}
              </SecondaryButton>
            </div>
            <div className="notification-list__filter">
              <Form
                form={form}
                name="notification-list__filter-form"
                layout="horizontal"
                onFinish={onPeriodChange}
                onValuesChange={(): void => form.submit()}
                autoComplete="off"
                validateTrigger="onSubmit"
                initialValues={{
                  dateRangeLocal: [dayjs(period.startAt), dayjs(period.endAt)],
                }}
              >
                <Form.Item
                  name="dateRangeLocal"
                  required
                  rules={[
                    {
                      required: true,
                      message: t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeRequired'),
                    },
                    {
                      validator: CleaningReportRequestDateRangeRestrictor.validate,
                    },
                  ]}
                >
                  <RangePicker disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate} />
                </Form.Item>
              </Form>
              <SecondaryButton
                size="s"
                onClick={(): void => setAreFiltersVisible(prevState => !prevState)}
                className={classNames('notification-list__filter-button', {
                  'button-active': areFiltersVisible,
                })}
                key="notification-list__filter-button"
                loading={areFiltersLoading}
              >
                <span>{t('notificationList.filter.filter')}</span>
                {getTotalFilterCount() > 0 && (
                  <span className="notification-list__filter-button-counter">{getTotalFilterCount() || ''}</span>
                )}
                <SvgIcon name="filter" className="notification-list__filter--button__icon" />
              </SecondaryButton>
            </div>

            <div
              className={classNames('notification-list__filter-wrapper-container', {
                'notification-list__filter-wrapper-container--hidden': !areFiltersVisible,
              })}
            >
              {areSeverityAndTypeFiltersEnabled && (
                <>
                  <div className="notification-list__select-wrapper">
                    <Select
                      className="notification-list__filter-select"
                      loading={areFiltersLoading}
                      onChange={handleSeverityFilterChange}
                      options={[
                        ...(availableFilters?.severities || []).map(severity => ({
                          label: t(`notifications.severities.${severity}`),
                          value: severity,
                        })),
                      ]}
                      {...SelectUtils.getSingleSelectionProps({
                        dropdownLabel: t('notificationList.filter.severity'),
                        dropdownVisibleState: isSeverityFilterOpen,
                        value: activeSeverityFilter ?? '',
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsSeverityFilterOpen(isOpen),
                        showSearch: false,
                      })}
                    />
                  </div>
                  <div className="notification-list__select-wrapper">
                    <Select
                      className="notification-list__filter-select"
                      loading={areFiltersLoading}
                      onChange={handleTypeFilterChange}
                      options={[
                        ...(availableFilters?.types || []).map(type => ({
                          label: t(`notifications.types.${type}`),
                          value: type,
                        })),
                      ]}
                      {...SelectUtils.getSingleSelectionProps({
                        dropdownLabel: t('notificationList.filter.type'),
                        dropdownVisibleState: isTypeFilterOpen,
                        value: activeTypeFilter ?? '',
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsTypeFilterOpen(isOpen),
                        showSearch: false,
                      })}
                    />
                  </div>
                </>
              )}

              {isSortingAndFilteringEnabled && (
                <>
                  <div className="notification-list__select-wrapper notification-list__site-select-wrapper">
                    <Select
                      className="notification-list__filter-select"
                      loading={areFiltersLoading}
                      onChange={handleSitesFilterChange}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: [
                          ...(availableFilters?.sites.map(site => ({
                            label: site.name,
                            value: site.id,
                          })) || []),
                        ],
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsSitesSelectOpen(isOpen),
                        dropdownVisibleState: isSitesSelectOpen,
                        valueArray: activeSitesFilter,
                        dropdownLabel: t('notificationList.filter.assignedSite'),
                      })}
                      //

                      showSearch={true}
                    />
                  </div>

                  <div className="notification-list__select-wrapper notification-list__name-select-wrapper">
                    <Select
                      className="notification-list__filter-select"
                      loading={areFiltersLoading}
                      onChange={handleMachineNamesFilterChange}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: (availableFilters?.machines || []).map(machine => ({
                          label: machine.name,
                          value: machine.id,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsMachineNamesSelectOpen(isOpen),
                        dropdownVisibleState: isMachineNamesSelectOpen,
                        valueArray: activeMachinesFilter,
                        dropdownLabel: t('notificationList.filter.machineName'),
                      })}
                    />
                  </div>

                  <div className="notification-list__select-wrapper notification-list__category-select-wrapper">
                    <Select
                      className="notification-list__filter-select"
                      loading={areFiltersLoading}
                      onChange={handleCategoriesFilterChange}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: filterCategories,
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsCategoriesSelectOpen(isOpen),
                        dropdownVisibleState: isCategoriesSelectOpen,
                        valueArray: activeCategoriesFilter,
                        dropdownLabel: t('notificationList.filter.categories'),
                      })}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="notification-list__body">
        <div className="notification-list__body-content" ref={tableRef}>
          <div className="notification-list__container--fluid">
            <Table
              dataSource={data}
              loading={isLoading || areFiltersLoading}
              className="notification-list__table"
              columns={tableColumns}
              rowKey="id"
              onChange={onTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('notificationList.table.noNotificationsFound')}
                  />
                ),
              }}
              // y value needs to match value set in CSS rule for
              // .ant-table-container, .ant-table-body
              infiniteScroll={{
                id: 'notification-list-infinite-scroll',
                next: loadMore,
                nextPaginationToken,
                isLoadingMoreData,
              }}
              showScrollButtons={true}
            />
          </div>
        </div>
      </div>
      {isNotificationSubscriptionDrawerVisible && <NotificationListSubscriptionDrawer />}
    </StyledNotificationList>
  );
};
