import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { NotificationClientListOptions, NotificationClientMachineListFilterOptions } from '../../NotificationClient';
import { NotificationList, NotificationListAvailableFilters } from '../../interfaces/Notification.types';
import { Optional } from 'lib/types/Optional';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  ISetActiveSortField,
  ISetActiveSortOrder,
} from 'app/modules/machine-inventory/machine-list/state/machineListActions';
import { MachinesAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';

// GET_NOTIFICATION_LIST
export type IGetNotificationListRequestOptions = NotificationClientListOptions;

export type IGetNotificationListRequestAction = Action<typeof NotificationListActions.GET_NOTIFICATION_LIST_REQUEST> & {
  payload: NotificationClientListOptions;
};

export type IGetNotificationListSuccessOptions = NotificationList;
export type IGetNotificationListSuccessAction = Action<typeof NotificationListActions.GET_NOTIFICATION_LIST_REQUEST> & {
  payload: NotificationList;
};

export type IGetNotificationListErrorAction = IErrorAction<typeof NotificationListActions.GET_NOTIFICATION_LIST_ERROR>;

// GET_NOTIFICATION_LIST_MORE_DATA
export type IGetNotificationListMoreDataRequestOptions = NotificationClientListOptions;

export type IGetNotificationListMoreDataRequestAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_REQUEST
> & {
  payload: NotificationClientListOptions;
};

export type IGetNotificationListMoreDataSuccessOptions = NotificationList;
export type IGetNotificationListMoreDataSuccessAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_REQUEST
> & {
  payload: NotificationList;
};

export type IGetNotificationListMoreDataErrorAction = IErrorAction<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_ERROR
>;

export type IChangeNotificationListTablePage = Action<typeof NotificationListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeNotificationListTablePageSize = Action<typeof NotificationListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IGetNotificationListFiltersRequestAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_REQUEST
>;

export type IGetNotificationListFiltersSuccessOptions = NotificationListAvailableFilters;
export type IGetNotificationListFiltersSuccessAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_SUCCESS
> & {
  payload: NotificationListAvailableFilters;
};

export type IGetNotificationListFiltersErrorAction = IErrorAction<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_ERROR
>;

export type ISetActiveSeverityFilter = Action<typeof NotificationListActions.SET_ACTIVE_SEVERITY_FILTER> & {
  payload: {
    severity: Optional<string>;
  };
};

export type ISetActiveTypeFilter = Action<typeof NotificationListActions.SET_ACTIVE_TYPE_FILTER> & {
  payload: {
    type: Optional<string>;
  };
};

export type ISetActiveCategoriesFilter = Action<typeof NotificationListActions.SET_ACTIVE_CATEGORIES_FILTER> & {
  payload: {
    categories: Optional<string[]>;
  };
};

export type ISetActiveSiteFilter = Action<typeof NotificationListActions.SET_ACTIVE_SITES_FILTER> & {
  payload: {
    sites: Optional<string[]>;
  };
};

export type ISetActiveMachinesFilter = Action<typeof NotificationListActions.SET_ACTIVE_MACHINES_FILTER> & {
  payload: {
    machines: Optional<string[]>;
  };
};

export type IGetMachineListFilterRequestOptions = NotificationClientMachineListFilterOptions;
export type IGetMachineListFilterRequestAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST
> & {
  payload: NotificationClientMachineListFilterOptions;
};

export type IGetMachineListFilterSuccessOptions = MachinesAvailableToFilter;
export type IGetMachineListFilterSuccessAction = Action<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_SUCCESS
> & {
  payload: MachinesAvailableToFilter;
};

export type IGetMachineListFilterErrorAction = IErrorAction<
  typeof NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_ERROR
>;

export class NotificationListActions {
  public static GET_NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_REQUEST';
  public static GET_NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_SUCCESS';
  public static GET_NOTIFICATION_LIST_ERROR = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_ERROR';

  public static GET_NOTIFICATION_LIST_MORE_DATA_REQUEST = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MORE_DATA_REQUEST';
  public static GET_NOTIFICATION_LIST_MORE_DATA_SUCCESS = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MORE_DATA_SUCCESS';
  public static GET_NOTIFICATION_LIST_MORE_DATA_ERROR = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MORE_DATA_ERROR';

  public static CHANGE_TABLE_PAGE = 'NOTIFICATION_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'NOTIFICATION_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'NOTIFICATION_LIST::RESET_STATE';

  public static GET_NOTIFICATION_LIST_FILTERS_REQUEST = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_FILTERS_REQUEST';
  public static GET_NOTIFICATION_LIST_FILTERS_SUCCESS = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_FILTERS_SUCCESS';
  public static GET_NOTIFICATION_LIST_FILTERS_ERROR = 'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_SEVERITY_FILTER = 'NOTIFICATION_LIST::SET_ACTIVE_SEVERITY_FILTER';
  public static SET_ACTIVE_TYPE_FILTER = 'NOTIFICATION_LIST::SET_ACTIVE_TYPE_FILTER';
  public static SET_ACTIVE_CATEGORIES_FILTER = 'NOTIFICATION_LIST::SET_ACTIVE_CATEGORY_FILTER';
  public static SET_ACTIVE_SITES_FILTER = 'NOTIFICATION_LIST::SET_ACTIVE_SITES_FILTER';
  public static SET_ACTIVE_MACHINES_FILTER = 'NOTIFICATION_LIST::SET_ACTIVE_MACHINES_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'NOTIFICATION_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'NOTIFICATION_LIST::SET_ACTIVE_SORT_ORDER';

  public static GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST =
    'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST';

  public static GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_SUCCESS =
    'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_SUCCESS';

  public static GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_ERROR =
    'NOTIFICATION_LIST::GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_ERROR';

  public static getNotificationListRequest({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: IGetNotificationListRequestOptions): IGetNotificationListRequestAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
        lang,
      },
    };
  }

  public static getNotificationListSuccess(
    data: IGetNotificationListSuccessOptions
  ): IGetNotificationListSuccessAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getNotificationListError({ error }: IActionCreatorErrorOptions): IGetNotificationListErrorAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_ERROR,
      error,
    };
  }

  public static getNotificationListMoreDataRequest({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: IGetNotificationListMoreDataRequestOptions): IGetNotificationListMoreDataRequestAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
        lang,
      },
    };
  }

  public static getNotificationListMoreDataSuccess(
    data: IGetNotificationListMoreDataSuccessOptions
  ): IGetNotificationListMoreDataSuccessAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getNotificationListMoreDataError({
    error,
  }: IActionCreatorErrorOptions): IGetNotificationListMoreDataErrorAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeNotificationListTablePage {
    return {
      type: NotificationListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeNotificationListTablePageSize {
    return {
      type: NotificationListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof NotificationListActions.RESET_STATE> {
    return {
      type: NotificationListActions.RESET_STATE,
    };
  }

  public static getNotificationListFiltersRequest(): IGetNotificationListFiltersRequestAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_REQUEST,
    };
  }

  public static getNotificationListFiltersSuccess(
    availableFilters: IGetNotificationListFiltersSuccessOptions
  ): IGetNotificationListFiltersSuccessAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getNotificationListFiltersError({
    error,
  }: IActionCreatorErrorOptions): IGetNotificationListFiltersErrorAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveSeverityFilter(severity: Optional<string>): ISetActiveSeverityFilter {
    return {
      type: NotificationListActions.SET_ACTIVE_SEVERITY_FILTER,
      payload: {
        severity,
      },
    };
  }

  public static setActiveTypeFilter(type: Optional<string>): ISetActiveTypeFilter {
    return {
      type: NotificationListActions.SET_ACTIVE_TYPE_FILTER,
      payload: {
        type,
      },
    };
  }

  public static setActiveCategoriesFilter(categories: Optional<string[]>): ISetActiveCategoriesFilter {
    return {
      type: NotificationListActions.SET_ACTIVE_CATEGORIES_FILTER,
      payload: {
        categories,
      },
    };
  }

  public static setActiveSitesFilter(sites: Optional<string[]>): ISetActiveSiteFilter {
    return {
      type: NotificationListActions.SET_ACTIVE_SITES_FILTER,
      payload: {
        sites,
      },
    };
  }

  public static setActiveMachinesFilter(machines: Optional<string[]>): ISetActiveMachinesFilter {
    return {
      type: NotificationListActions.SET_ACTIVE_MACHINES_FILTER,
      payload: {
        machines,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: NotificationListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: NotificationListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getMachineListFilterRequest({
    filter,
  }: IGetMachineListFilterRequestOptions): IGetMachineListFilterRequestAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getMachineListFilterSuccess(
    data: IGetMachineListFilterSuccessOptions
  ): IGetMachineListFilterSuccessAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListFilterError({ error }: IActionCreatorErrorOptions): IGetMachineListFilterErrorAction {
    return {
      type: NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_ERROR,
      error,
    };
  }
}
