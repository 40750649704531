import { createSlice } from '@reduxjs/toolkit';
import { IShowDeleteNoteModalAction, IShowEditNoteModalAction } from './noteModalsActions.types';
import { MachineNote } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface NoteModalsState {
  addNoteModal: {
    visible: boolean;
    isLoading: boolean;
  };
  editNoteModal: {
    noteToEdit: Optional<MachineNote>;
    visible: boolean;
    isLoading: boolean;
  };
  deleteNoteModal: {
    noteToDelete: Optional<MachineNote>;
    visible: boolean;
    isLoading: boolean;
  };
}

export const initialState: NoteModalsState = {
  addNoteModal: {
    visible: false,
    isLoading: false,
  },
  editNoteModal: {
    noteToEdit: null,
    visible: false,
    isLoading: false,
  },
  deleteNoteModal: {
    noteToDelete: null,
    visible: false,
    isLoading: false,
  },
};

const noteModalsSlice = createSlice({
  name: 'noteModals',
  initialState,
  reducers: {
    showAddNoteModal: state => {
      state.addNoteModal.visible = true;
      return state;
    },
    hideAddNoteModal: state => {
      state.addNoteModal.visible = false;
      return state;
    },
    addNoteModalIsLoading: state => {
      state.addNoteModal.isLoading = true;
      return state;
    },
    addNoteModalIsNotLoading: state => {
      state.addNoteModal.isLoading = false;
      return state;
    },
    showEditNoteModal: (state, action: IShowEditNoteModalAction) => {
      state.editNoteModal.visible = true;
      state.editNoteModal.noteToEdit = action.payload.noteToEdit;
      return state;
    },
    hideEditNoteModal: state => {
      state.editNoteModal.visible = false;
      state.editNoteModal.noteToEdit = null;
      return state;
    },
    editNoteModalIsLoading: state => {
      state.editNoteModal.isLoading = true;
      return state;
    },
    editNoteModalIsNotLoading: state => {
      state.editNoteModal.isLoading = false;
      return state;
    },

    showDeleteNoteModal: (state, action: IShowDeleteNoteModalAction) => {
      state.deleteNoteModal.visible = true;
      state.deleteNoteModal.noteToDelete = action.payload.noteToDelete;
      return state;
    },
    hideDeleteNoteModal: state => {
      state.deleteNoteModal.visible = false;
      state.deleteNoteModal.noteToDelete = null;
      return state;
    },
    deleteNoteModalIsLoading: state => {
      state.deleteNoteModal.isLoading = true;
      return state;
    },
    deleteNoteModalIsNotLoading: state => {
      state.deleteNoteModal.isLoading = false;
      return state;
    },
  },
});

export const NoteModalsActions = noteModalsSlice.actions;
export const noteModalsReducer = noteModalsSlice.reducer;
