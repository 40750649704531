import {
  ResponseBoolean,
  Reminder,
  RepetitionInterval,
  ResponseRemindersList,
  ResponseReminderCreate,
} from '../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';

export enum ReminderTopicPreset {
  DGUV3 = 'DGUV3',
  SERVICE_REMINDER = 'SERVICE_REMINDER',
}

export interface ReminderDelete {
  reminderDelete: ResponseBoolean;
}

export interface RemindersListResponse {
  machine: {
    data: {
      reminders: ResponseRemindersList;
    };
  };
}

export type { Reminder };

export { RepetitionInterval as ReminderRepetitionInterval };

export interface ReminderCreateResponse {
  reminderCreate: ResponseReminderCreate;
}
