import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export class PriorityNotificationsSelectors {
  public static selectData = (state: IState): Optional<Notification[]> =>
    state.modules.overview['priority-notifications'].data;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules.overview['priority-notifications'].isLoading;

  public static selectPaginationTokens = (state: IState): PaginationTokens =>
    state.modules.overview['priority-notifications'].paginationTokens;
}
