import { ColumnsType, ColumnType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { i18n as i18next } from 'i18next';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { NotificationIcon } from 'app/modules/notification/components/NotificationIcon/NotificationIcon';
import { NotificationViewModel } from 'app/modules/notification/NotificationViewModel';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';

export const getNotificationListColumns = ({
  t,
  i18n,
  isSortingAndFilteringEnabled,
  haveRobotsAvailable,
  goToMachineDetails,
  handleClickSiteName,
}: {
  t: TFunction;
  i18n: i18next;
  isSortingAndFilteringEnabled: boolean;
  haveRobotsAvailable: boolean;
  goToMachineDetails: (machineId: string) => void;
  handleClickSiteName: (siteId?: string) => void;
}): ColumnsType<Notification> => {
  let columnWidths: {
    icon: number;
    description: number;
    machineName: number;
    type: number;
    materialNumber?: number;
    serialNumber?: number;
    assignedSite: number;
    timestamp: number;
  } = {
    icon: 48,
    description: 271,
    machineName: 180,
    type: 180,
    materialNumber: 123,
    serialNumber: 105,
    assignedSite: 180,
    timestamp: 201,
  };

  if (haveRobotsAvailable) {
    columnWidths = {
      icon: 48,
      description: 271,
      machineName: 180,
      type: 180,
      materialNumber: 123,
      serialNumber: 105,
      assignedSite: 180,
      timestamp: 201,
    };
  }

  const columns: ColumnsType<Notification> = [
    {
      width: columnWidths.icon,
      render: ({ severity, type }): ReactNode => <NotificationIcon severity={severity} type={type} />,
      className: 'notification-list__icon-column',
    },
  ];

  if (haveRobotsAvailable) {
    columns.push({
      title: t('notificationList.table.columns.description'),
      dataIndex: 'description',
      key: 'description',
      width: columnWidths.description,
      render: (_: string, notification: Notification): JSX.Element => {
        const content = new NotificationViewModel(notification).moreInformation;
        const isShowTooltip = new NotificationViewModel(notification).isExpandable;

        return isShowTooltip && content ? (
          <Tooltip title={<InfoTooltip content={content} />} overlayClassName="tooltip-overlay">
            {new NotificationViewModel(notification).description}
          </Tooltip>
        ) : (
          <>{new NotificationViewModel(notification).description}</>
        );
      },
      ellipsis: {
        showTitle: true,
      },
    } as ColumnType<Notification>);
  }

  columns.push({
    title: t('notificationList.table.columns.machineName'),
    dataIndex: ['machine', 'name'],
    width: columnWidths.machineName,
    key: 'machine.name',
    render: (machineName: string, notification: Notification): JSX.Element => (
      <div onClick={(): void => goToMachineDetails(notification.machine.id)}>
        <span className="table__link--no-styling">{machineName}</span>
      </div>
    ),
    ellipsis: {
      showTitle: true,
    },
  });

  columns.push({
    title: t('notificationList.table.columns.type'),
    dataIndex: ['machine', 'type', 'name'],
    width: columnWidths.machineName,
    key: 'machine.type.name',
    ellipsis: {
      showTitle: true,
    },
  });

  if (haveRobotsAvailable) {
    columns.push({
      title: t('notificationList.table.columns.materialNo'),
      dataIndex: ['machine', 'materialNumber'],
      width: columnWidths.materialNumber,
      key: 'machine.materialNumber',
      ellipsis: {
        showTitle: true,
      },
    } as ColumnType<Notification>);

    columns.push({
      title: t('notificationList.table.columns.serialNo'),
      dataIndex: ['machine', 'serialNumber'],
      width: columnWidths.serialNumber,
      key: 'machine.serialNumber',
      ellipsis: {
        showTitle: true,
      },
    } as ColumnType<Notification>);
  }

  columns.push(
    ...([
      {
        title: t('notificationList.table.columns.site'),
        dataIndex: ['machine', 'site', 'name'],
        width: columnWidths.assignedSite,
        key: 'machine.site.name',
        render: (_siteName: string, notification: Notification): JSX.Element => {
          if (!notification.machine.site?.name || !notification.machine.site?.id) {
            return <></>;
          }

          return (
            <div
              className="table__link--no-styling"
              onClick={(): void => handleClickSiteName(notification.machine.site?.id)}
            >
              {notification.machine.site?.name}
            </div>
          );
        },
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: t('notificationList.table.columns.timestamp'),
        dataIndex: 'timestamp',
        width: columnWidths.timestamp,
        key: 'timestamp',
        sorter: isSortingAndFilteringEnabled,
        defaultSortOrder: 'descend',
        render: (timestamp): JSX.Element => (
          <div className="notification-list__timestamp">
            {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.DATE)}{' '}
            {DateTime.formatDateByLocale(i18n.language, timestamp, DATE_FORMAT_PATTERN.TIME)}
          </div>
        ),
      },
    ] as ColumnType<Notification>[])
  );

  if (!haveRobotsAvailable) {
    columns.push({
      title: t('notificationList.table.columns.description'),
      dataIndex: 'description',
      key: 'description',
      width: columnWidths.description,
      render: (_: string, notification: Notification): JSX.Element => (
        <>{new NotificationViewModel(notification).description}</>
      ),
      ellipsis: {
        showTitle: true,
      },
    } as ColumnType<Notification>);
  }

  return columns;
};
