import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTopicSuggestionAutoComplete } from './TopicSuggestionAutoComplete.styles';
import { AutoCompleteProps } from 'lib/components/AutoComplete/AutoComplete';
import { ReminderTopicPreset } from 'app/modules/reminder/interfaces/Reminder.types';

type TopicSuggestionAutoCompleteProps = AutoCompleteProps;

export const TopicSuggestionAutoComplete = (props: TopicSuggestionAutoCompleteProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTopicSuggestionAutoComplete
        className="reminder-create-modal__auto-complete"
        placeholder={t('reminder.reminderCreate.placeholder.topic')}
        options={Object.values(ReminderTopicPreset).map(topic => ({
          value: t(`machineReminder.topicPreset.${topic}`),
          label: t(`machineReminder.topicPreset.${topic}`),
        }))}
        {...props}
      />
    </>
  );
};
