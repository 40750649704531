import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import {
  IGetMachineRemindersRequestAction,
  MachineDetailsActions,
} from '../../machine-inventory/machine-details/state/machineDetailsActions';
import { MACHINE_REMINDER_LIST_PAGE_SIZE } from '../../machine-inventory/utils/constants';
import { ReminderDelete, ReminderCreateResponse } from '../interfaces/Reminder.types';
import {
  ICreateReminderModalIsLoadingAction,
  ICreateReminderModalIsNotLoadingAction,
  IDeleteReminderModalIsLoadingAction,
  IDeleteReminderModalIsNotLoadingAction,
  IHideDeleteReminderModalAction,
  IHideReminderCreateModalAction,
  ReminderModalsActions,
} from '../modals/state/reminderModalsActions';
import {
  IDeleteReminderRequestAction,
  IDeleteReminderSuccessAction,
  IDeleteReminderErrorAction,
  ReminderActions,
  ICreateReminderRequestAction,
  ICreateReminderSuccessAction,
  ICreateReminderErrorAction,
} from './reminderActions';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';

export function* deleteReminderSaga(
  action: IDeleteReminderRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<ReminderDelete>>
  | PutEffect<IDeleteReminderSuccessAction>
  | PutEffect<IDeleteReminderErrorAction>
  | PutEffect<IDeleteReminderModalIsLoadingAction>
  | PutEffect<IDeleteReminderModalIsNotLoadingAction>
  | PutEffect<IHideDeleteReminderModalAction>,
  void,
  IDependencies
> {
  const { machineId, id } = action.payload;
  const { reminderService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(ReminderModalsActions.deleteReminderModalIsLoading());
    const response = yield* call(reminderService.delete, { machineId, id });

    yield* put(ReminderActions.deleteReminderSuccess(response));

    yield* put(
      MachineDetailsActions.getMachineRemindersRequest({
        machineId,
        paginationOptions: {
          paginationToken: '',
          limit: MACHINE_REMINDER_LIST_PAGE_SIZE,
        },
        append: false,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      ReminderActions.deleteReminderError({
        error,
      })
    );
  } finally {
    yield* put(ReminderModalsActions.deleteReminderModalIsNotLoading());
    yield* put(ReminderModalsActions.hideDeleteReminderModal());
  }
}

export function* createReminderSaga(
  action: ICreateReminderRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<ReminderCreateResponse> | void>
  | PutEffect<ICreateReminderModalIsLoadingAction>
  | PutEffect<ICreateReminderSuccessAction>
  | PutEffect<IGetMachineRemindersRequestAction>
  | PutEffect<ICreateReminderErrorAction>
  | PutEffect<IHideReminderCreateModalAction>
  | PutEffect<ICreateReminderModalIsNotLoadingAction>,
  void,
  IDependencies
> {
  const { reminderService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const { machineId } = action.payload;

    yield* put(ReminderModalsActions.createReminderModalIsLoading());

    const response = yield* call(reminderService.create, action.payload);

    if (response?.reminderCreate?.data) {
      yield* put(ReminderActions.createReminderSuccess(response));
      yield* call(toastService.success, {
        message: t('reminder.reminderCreate.toast.success.message'),
        description: t('reminder.reminderCreate.toast.success.description'),
      });
      yield* put(ReminderModalsActions.hideReminderCreateModal());
      yield* put(
        MachineDetailsActions.getMachineRemindersRequest({
          machineId,
          paginationOptions: {
            paginationToken: '',
            limit: MACHINE_REMINDER_LIST_PAGE_SIZE,
          },
          append: false,
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* put(
      ReminderActions.createReminderError({
        error,
      })
    );

    yield* call(toastService.error, {
      message: t('reminder.reminderCreate.toast.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(ReminderModalsActions.createReminderModalIsNotLoading());
  }
}

export function* reminderSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(ReminderActions.DELETE_REMINDER_REQUEST, deleteReminderSaga);
  yield* takeLatest(ReminderActions.CREATE_REMINDER_REQUEST, createReminderSaga);
}
