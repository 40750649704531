import { produce } from 'immer';
import { AnyAction } from 'redux';
import { NotificationModalActions } from './notificationModalsActions';
import { NotificationUser } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface NotificationModalsState {
  notificationModal: {
    visible: boolean;
    isLoading: boolean;
    isSaving: boolean;
  };
  notificationSubscription: {
    data: Optional<NotificationUser>;
    isLoading: boolean;
  };
  saveNotificationSubscription: {
    data: Optional<NotificationUser>;
    isLoading: boolean;
  };
}

export const initialState: NotificationModalsState = {
  notificationModal: {
    visible: false,
    isLoading: true,
    isSaving: false,
  },
  notificationSubscription: {
    data: null,
    isLoading: false,
  },
  saveNotificationSubscription: {
    data: null,
    isLoading: false,
  },
};

export const notificationModalReducer = (state = initialState, action: AnyAction): NotificationModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case NotificationModalActions.SHOW_NOTIFICATION_SUBSCRIPTION_MODAL: {
        draft.notificationModal.visible = true;
        return draft;
      }

      case NotificationModalActions.HIDE_NOTIFICATION_SUBSCRIPTION_MODAL: {
        draft.notificationModal.visible = false;
        return draft;
      }

      case NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_LOADING: {
        draft.notificationModal.isLoading = true;
        return draft;
      }

      case NotificationModalActions.NOTIFICATION_SUBSCRIPTION_MODAL_IS_NOT_LOADING: {
        draft.notificationModal.isLoading = false;
        return draft;
      }

      case NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_REQUEST: {
        draft.notificationSubscription.isLoading = true;
        return draft;
      }

      case NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_SUCCESS: {
        const { notificationGetUser } = action.payload;
        draft.notificationSubscription.data = notificationGetUser?.data || {};
        draft.notificationSubscription.isLoading = false;
        return draft;
      }

      case NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_ERROR: {
        draft.notificationSubscription.isLoading = false;
        return draft;
      }

      case NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_RESET_STATE: {
        draft.notificationSubscription.data = initialState.notificationSubscription.data;
        return draft;
      }

      case NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_REQUEST: {
        draft.saveNotificationSubscription.isLoading = true;
        return draft;
      }

      case NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_SUCCESS: {
        const {
          notificationUpdateNotifyStatus: { data },
        } = action.payload;
        draft.saveNotificationSubscription.data = data;
        draft.saveNotificationSubscription.isLoading = false;
        return draft;
      }

      case NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_ERROR: {
        draft.saveNotificationSubscription.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
