import styled from 'styled-components';

export const StyledNotificationIcon = styled.div`
  .notification-icon__content {
    display: block;
    vertical-align: middle;
  }
  .notification-icon__icon {
    width: 26px;
    height: 26px;
  }
`;
