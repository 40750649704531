import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { NotificationListAvailableFilters } from '../../interfaces/Notification.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';
import {
  Notification,
  Severity,
  SortOrders,
  Type,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const selectData = (state: IState): Optional<Notification[]> =>
  state.modules.notification['notification-list'].data;

export const selectTotalCount = (state: IState): Optional<number> =>
  state.modules.notification['notification-list'].totalCount;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules.notification['notification-list'].isLoading;

export const selectIsLoadingMoreData = (state: IState): Optional<boolean> =>
  state.modules.notification['notification-list'].isLoadingMoreData;

export const selectPaginationTokens = (state: IState): PaginationTokens =>
  state.modules.notification['notification-list'].paginationTokens;

export const selectNextPaginationToken = (state: IState): string | null =>
  state.modules.notification['notification-list'].nextPaginationToken;

export const selectPage = (state: IState): number => state.modules.notification['notification-list'].page;

export const selectPageSize = (state: IState): number => state.modules.notification['notification-list'].pageSize;

export const selectFilters = (state: IState): Optional<NotificationListAvailableFilters> =>
  state.modules.notification['notification-list'].filters.available;

export const selectAreFiltersLoading = (state: IState): boolean =>
  state.modules.notification['notification-list'].filters.isLoading;

export const selectActiveSeverityFilter = (state: IState): Optional<Severity> =>
  state.modules.notification['notification-list'].filters.active.severity || undefined;

export const selectActiveTypeFilter = (state: IState): Optional<Type> =>
  state.modules.notification['notification-list'].filters.active.type || undefined;

export const selectActiveSitesFilter = (state: IState): Optional<string[]> =>
  state.modules.notification['notification-list'].filters.active.sites || undefined;

export const selectActiveMachinesFilter = (state: IState): Optional<string[]> =>
  state.modules.notification['notification-list'].filters.active.machines || undefined;

export const selectActiveCategoriesFilter = (state: IState): Optional<string[]> =>
  state.modules.notification['notification-list'].filters.active.categories || undefined;

export const selectSortField = (state: IState): Optional<string> =>
  state.modules.notification['notification-list'].sortOptions.field;

export const selectSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules.notification['notification-list'].sortOptions.order;
