import { singleton } from 'tsyringe';
import { ReminderCreateResponse, ReminderDelete, RemindersListResponse } from './interfaces/Reminder.types';
import {
  ReminderClient,
  ReminderClientCreateOptions,
  ReminderClientDeleteOptions,
  ReminderClientListOptions,
} from './ReminderClient';
import { Optional } from 'lib/types/Optional';

export type ReminderServiceListOptions = ReminderClientListOptions;
export type ReminderServiceCreateOptions = ReminderClientCreateOptions;

@singleton()
export class ReminderService {
  constructor(private reminderClient: ReminderClient) {}

  public list = async ({
    machineId,
    paginationOptions,
  }: ReminderServiceListOptions): Promise<RemindersListResponse> => {
    const { data } = await this.reminderClient.list({
      machineId,
      paginationOptions,
    });

    return data;
  };

  public delete = async ({ machineId, id }: ReminderClientDeleteOptions): Promise<Optional<ReminderDelete>> => {
    const res = await this.reminderClient.delete({ machineId, id });
    const { data } = res;
    return data;
  };

  public create = async ({
    description,
    localRepetitiveDate,
    localRepetitiveTime,
    machineId,
    repetition,
    startAt,
    timezone,
    topic,
  }: ReminderServiceCreateOptions): Promise<Optional<ReminderCreateResponse>> => {
    const { data } = await this.reminderClient.create({
      description,
      localRepetitiveDate,
      localRepetitiveTime,
      machineId,
      repetition,
      startAt,
      timezone,
      topic,
    });

    return data;
  };
}
