import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { ReminderCreateResponse, ReminderDelete, RemindersListResponse } from './interfaces/Reminder.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputPagingOptions,
  InputReminderCreate,
  MutationReminderDeleteArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface ReminderClientListOptions {
  machineId: string;
  paginationOptions: InputPagingOptions;
}

export type ReminderClientDeleteOptions = MutationReminderDeleteArgs;
export type ReminderClientCreateOptions = InputReminderCreate;

@injectable()
export class ReminderClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    machineId,
    paginationOptions,
  }: ReminderClientListOptions): Promise<ApolloQueryResult<RemindersListResponse>> =>
    this.client.query({
      query: gql`
        query MachineReminders($machineId: ID!, $paginationOptions: InputPagingOptions!) {
          machine(id: $machineId) {
            data {
              id
              reminders(paginationOptions: $paginationOptions) {
                data {
                  description
                  id
                  lastRemindAt
                  localRepetitiveDate
                  localRepetitiveTime
                  machineId
                  nextRemindAt
                  repetition
                  startAt
                  timezone
                  topic
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
          }
        }
      `,
      variables: {
        machineId,
        paginationOptions,
      },
    });

  public delete = async ({ id, machineId }: ReminderClientDeleteOptions): Promise<FetchResult<ReminderDelete>> =>
    this.client.mutate({
      mutation: gql`
        mutation ReminderDelete($id: ID!, $machineId: ID!) {
          reminderDelete(id: $id, machineId: $machineId) {
            data
          }
        }
      `,
      variables: {
        id,
        machineId,
      },
    });

  public create = async ({
    description,
    localRepetitiveDate,
    localRepetitiveTime,
    machineId,
    repetition,
    startAt,
    timezone,
    topic,
  }: ReminderClientCreateOptions): Promise<FetchResult<ReminderCreateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation ReminderCreate($input: InputReminderCreate!) {
          reminderCreate(input: $input) {
            data {
              description
              id
              topic
              timezone
              startAt
              repetition
              nextRemindAt
              machineId
              localRepetitiveTime
              localRepetitiveDate
              lastRemindAt
            }
          }
        }
      `,
      variables: {
        input: {
          description,
          localRepetitiveDate,
          localRepetitiveTime,
          machineId,
          repetition,
          startAt,
          timezone,
          topic,
        },
      },
    });
}
