import { produce } from 'immer';
import { AnyAction } from 'redux';
import { PriorityNotificationsActions } from './priorityNotificationsActions';
import { DEFAULT_PAGE_VALUE } from 'config/constants';
import { Optional } from 'lib/types/Optional';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Notification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface PriorityNotificationsState {
  data: Optional<Notification[]>;
  isLoading: Optional<boolean>;
  paginationTokens: PaginationTokens;
}

export const initialState: PriorityNotificationsState = {
  data: null,
  isLoading: null,
  paginationTokens: {},
};

export const priorityNotificationsReducer = (state = initialState, action: AnyAction): PriorityNotificationsState =>
  produce(state, draft => {
    switch (action.type) {
      case PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_SUCCESS: {
        const {
          notifications: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        if (paginationToken) {
          draft.paginationTokens[DEFAULT_PAGE_VALUE + 1] = paginationToken;
        }
        return draft;
      }

      case PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case PriorityNotificationsActions.RESET_STATE: {
        return initialState;
      }

      default:
        return draft;
    }
  });
