import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetNotificationSubscriptionErrorAction,
  IGetNotificationSubscriptionSuccessAction,
  IHideNotificationSubscriptionModalAction,
  ISaveNotificationSubscriptionErrorAction,
  ISaveNotificationSubscriptionRequestAction,
  ISaveNotificationSubscriptionSuccessAction,
  NotificationModalActions,
} from './notificationModalsActions';
import { Optional } from 'lib/types/Optional';
import {
  ResponseNotificationUpdateNotifyStatus,
  ResponseNotificationUser,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getNotificationSubscriptionSaga(): Generator<
  | GetContextEffect
  | CallEffect<Optional<ResponseNotificationUser>>
  | PutEffect<IGetNotificationSubscriptionSuccessAction>
  | PutEffect<IGetNotificationSubscriptionErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.getUserNotification);

    yield* put(NotificationModalActions.getNotificationSubscriptionSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      NotificationModalActions.getNotificationSubscriptionError({
        error,
      })
    );
  }
}

export function* saveNotificationSubscriptionSaga(
  action: ISaveNotificationSubscriptionRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<ResponseNotificationUpdateNotifyStatus>>
  | CallEffect<void>
  | PutEffect<ISaveNotificationSubscriptionSuccessAction>
  | PutEffect<ISaveNotificationSubscriptionErrorAction>
  | PutEffect<IHideNotificationSubscriptionModalAction>,
  void,
  IDependencies
> {
  const { notificationService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.updateNotifyStatus, action.payload);

    yield* put(NotificationModalActions.saveNotificationSubscriptionSuccess(response));
    yield* put(NotificationModalActions.hideNotificationSubscriptionModal());

    yield* call(toastService.success, {
      message: t('notificationList.notificationSubscription.toast.success.message'),
      description: t('notificationList.notificationSubscription.toast.success.description'),
    });
  } catch (error) {
    console.error(error);

    yield* put(
      NotificationModalActions.saveNotificationSubscriptionError({
        error,
      })
    );
  }
}

export function* notificationModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(NotificationModalActions.GET_NOTIFICATION_SUBSCRIPTION_REQUEST, getNotificationSubscriptionSaga);
  yield* takeLatest(NotificationModalActions.SAVE_NOTIFICATION_SUBSCRIPTION_REQUEST, saveNotificationSubscriptionSaga);
}
