import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledCreateReminderModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px;

    .ant-modal-title {
      padding: 0;
    }
  }

  .reminder-create-modal__description {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: ${(props): string => props.theme.lineHeights.p0};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
    color: ${(props): string => props.theme.colors.black};
    margin: 0;
  }

  .reminder-create-modal__sub-heading {
    ${(props): string => props.theme.fontStyles.heading3};
    line-height: ${(props): string => props.theme.lineHeights.heading4};
    color: ${(props): string => props.theme.colors.black};
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;
