import styled from 'styled-components';

export const StyledHighPriorityNotifications = styled.div`
  .high-priority-notifications__table {
    .ant-row {
      justify-content: space-between;
    }

    tr td {
      height: 60px;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th {
      padding: 5px 16px;
    }

    .ant-table-column-sorters {
      display: block;
    }

    .ant-table-column-sorter {
      display: none;
    }

    .ant-table-footer {
      display: none;
    }
  }

  .ant-spin-nested-loading {
    min-height: 368px;
  }

  .ant-table-empty {
    table {
      min-height: 368px;
    }
  }
`;
