import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { ReminderClientCreateOptions, ReminderClientDeleteOptions } from '../ReminderClient';
import { ReminderCreateResponse, ReminderDelete } from '../interfaces/Reminder.types';
import { Optional } from 'lib/types/Optional';

export type IDeleteReminderRequestOptions = ReminderClientDeleteOptions;
export type IDeleteReminderRequestAction = Action<typeof ReminderActions.DELETE_REMINDER_REQUEST> & {
  payload: ReminderClientDeleteOptions;
};

export type IDeleteReminderSuccessOptions = Optional<ReminderDelete>;
export type IDeleteReminderSuccessAction = Action<typeof ReminderActions.DELETE_REMINDER_SUCCESS> & {
  payload: Optional<ReminderDelete>;
};

export type IDeleteReminderErrorAction = IErrorAction<typeof ReminderActions.DELETE_REMINDER_ERROR>;

export type ICreateReminderRequestOptions = ReminderClientCreateOptions;

export type ICreateReminderRequestAction = Action<typeof ReminderActions.CREATE_REMINDER_REQUEST> & {
  payload: ReminderClientCreateOptions;
};

export type ICreateReminderSuccessOptions = Optional<ReminderCreateResponse>;
export type ICreateReminderSuccessAction = Action<typeof ReminderActions.CREATE_REMINDER_SUCCESS> & {
  payload: Optional<ReminderCreateResponse>;
};

export type ICreateReminderErrorAction = IErrorAction<typeof ReminderActions.CREATE_REMINDER_ERROR>;

export class ReminderActions {
  public static DELETE_REMINDER_REQUEST = 'REMINDER::DELETE_REMINDER_REQUEST';
  public static DELETE_REMINDER_SUCCESS = 'REMINDER::DELETE_REMINDER_SUCCESS';
  public static DELETE_REMINDER_ERROR = 'REMINDER::DELETE_REMINDER_ERROR';

  public static CREATE_REMINDER_REQUEST = 'REMINDER::CREATE_REMINDER_REQUEST';
  public static CREATE_REMINDER_SUCCESS = 'REMINDER::CREATE_REMINDER_SUCCESS';
  public static CREATE_REMINDER_ERROR = 'REMINDER::CREATE_REMINDER_ERROR';

  public static deleteReminderRequest({ machineId, id }: IDeleteReminderRequestOptions): IDeleteReminderRequestAction {
    return {
      type: ReminderActions.DELETE_REMINDER_REQUEST,
      payload: {
        machineId,
        id,
      },
    };
  }

  public static deleteReminderSuccess(data: IDeleteReminderSuccessOptions): IDeleteReminderSuccessAction {
    return {
      type: ReminderActions.DELETE_REMINDER_SUCCESS,
      payload: data,
    };
  }

  public static deleteReminderError({ error }: IActionCreatorErrorOptions): IDeleteReminderErrorAction {
    return {
      type: ReminderActions.DELETE_REMINDER_ERROR,
      error,
    };
  }

  public static createReminderRequest({
    description,
    localRepetitiveDate,
    localRepetitiveTime,
    machineId,
    repetition,
    startAt,
    timezone,
    topic,
  }: ICreateReminderRequestOptions): ICreateReminderRequestAction {
    return {
      type: ReminderActions.CREATE_REMINDER_REQUEST,
      payload: {
        description,
        localRepetitiveDate,
        localRepetitiveTime,
        machineId,
        repetition,
        startAt,
        timezone,
        topic,
      },
    };
  }

  public static createReminderSuccess(data: ICreateReminderSuccessOptions): ICreateReminderSuccessAction {
    return {
      type: ReminderActions.CREATE_REMINDER_SUCCESS,
      payload: data,
    };
  }

  public static createReminderError({ error }: IActionCreatorErrorOptions): ICreateReminderErrorAction {
    return {
      type: ReminderActions.CREATE_REMINDER_ERROR,
      error,
    };
  }
}
