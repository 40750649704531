import { InfoCircleOutlined } from '@ant-design/icons';
import { getI18n, useTranslation } from 'react-i18next';
import { useAnalyticsSetPageInfo } from '../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { StyledService } from './Service.styles';
import { useAppConfig } from 'app/cross-cutting-concerns/configuration/hooks/useAppConfig';
import serviceImage from 'app/assets/images/service/service.jpeg';

export const Service = (): JSX.Element => {
  const { t } = useTranslation();
  const { language } = getI18n();
  const { service } = useAppConfig();

  useAnalyticsSetPageInfo({});

  const employeeName = service[language]?.contactPerson?.employeeName || '';
  const email = service[language]?.contactPerson?.email || '';
  const phoneNumber = service[language]?.contactPerson?.phoneNumber || '';

  return (
    <StyledService className="service">
      <div className="service__header">
        <div className="service__header-content">
          <div className="service__container--narrow">
            <h1 className="service__title">{t('service.title')}</h1>
            <pre className="service__description">{t('service.description')}</pre>
          </div>
        </div>
      </div>

      <div className="service__body">
        <div className="service__body-content">
          <div className="service__container service__container--narrow">
            <div className="service__body-content-img-block">
              <img src={serviceImage} alt="" />
              <p className="service__body-content-img-block-image-title">{t('service.imgTitle')}</p>
            </div>
            <div className="service__body-info">
              <p className="service__body-info-title">{t('service.yourContact')}</p>
              <div className="service__body-info-item">
                <p className="service__body-info-item-title">{t('service.employee').toUpperCase()}</p>
                <p className="service__body-info-item-content">{employeeName}</p>
              </div>
              <div className="service__body-info-item">
                <p className="service__body-info-item-title">{t('service.email').toUpperCase()}</p>
                <p className="service__body-info-item-content">{email}</p>
              </div>
              <div className="service__body-info-item">
                <p className="service__body-info-item-title">{t('service.mobileNumber').toUpperCase()}</p>
                <p className="service__body-info-item-content">{phoneNumber}</p>
              </div>
              <div className="service__body-info-item">
                <p className="service__body-info-item-title">
                  <InfoCircleOutlined className="service__body-info-item-title-icon" />
                  {t('service.note').toUpperCase()}
                </p>
                <p className="service__body-info-item-content">{t('service.contactPersonNote')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledService>
  );
};
