import { useSelector } from 'react-redux';
import { useAnalyticsSetPageInfo } from '../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { StyledPrivacyPolicy } from './PrivacyPolicy.styles';
import { privacyPolicyContentDe } from './language/de/PrivacyPolicyHtml';
import { privacyPolicyContentPt } from './language/pt/PrivacyPolicyHtml';
import { selectUserCountryCode } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { Country } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';

export const PrivacyPolicy = (): JSX.Element => {
  useAnalyticsSetPageInfo({});
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);

  // TODO: Add privacy policy content for other languages when having the content
  const ContentByCountryMap: Record<Country, any> = {
    [Country.AUSTRALIA]: privacyPolicyContentDe,
    [Country.AUSTRIA]: privacyPolicyContentDe,
    [Country.BELGIUM]: privacyPolicyContentDe,
    [Country.CZECH_REPUBLIC]: privacyPolicyContentDe,
    [Country.DENMARK]: privacyPolicyContentDe,
    [Country.FRANCE]: privacyPolicyContentDe,
    [Country.GERMANY]: privacyPolicyContentDe,
    [Country.ITALY]: privacyPolicyContentDe,
    [Country.NETHERLANDS]: privacyPolicyContentDe,
    [Country.NORWAY]: privacyPolicyContentDe,
    [Country.SWEDEN]: privacyPolicyContentDe,
    [Country.SWITZERLAND]: privacyPolicyContentDe,
    [Country.UNITED_KINGDOM]: privacyPolicyContentDe,
    [Country.USA]: privacyPolicyContentDe,
    [Country.SPAIN]: privacyPolicyContentDe,
    [Country.JAPAN]: privacyPolicyContentDe,
    [Country.PORTUGAL]: privacyPolicyContentPt,
    [Country.KOREA]: privacyPolicyContentDe,
  };

  const content = ContentByCountryMap[country];

  return (
    <StyledPrivacyPolicy className="privacy-policy">
      <div className="privacy-policy__content" dangerouslySetInnerHTML={{ __html: content }}></div>
    </StyledPrivacyPolicy>
  );
};
