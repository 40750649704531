import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { NotificationUser } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const selectIsNotificationSubscriptionModalVisible = (state: IState): boolean =>
  state.modules.notification.modals.notificationModal.visible;

export const selectIsSaveNotificationSubscriptionModalSaving = (state: IState): boolean =>
  state.modules.notification.modals.saveNotificationSubscription.isLoading;

export const selectIsGetNotificationSubscriptionModalLoading = (state: IState): boolean =>
  state.modules.notification.modals.notificationSubscription.isLoading;

export const selectGetNotificationSubscriptionData = (state: IState): Optional<NotificationUser> =>
  state.modules.notification.modals.notificationSubscription.data;
