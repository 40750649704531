import { produce } from 'immer';
import { AnyAction } from 'redux';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { NotificationListAvailableFilters } from '../../interfaces/Notification.types';
import { NotificationListActions } from './notificationListActions';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import { Optional } from 'lib/types/Optional';
import {
  Notification,
  Severity,
  SortOrders,
  Type,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getNextPaginationToken } from 'app/utils/infinite-scroll/infiniteScroll';

export interface NotificationListState {
  data: Optional<Notification[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  isLoadingMoreData: Optional<boolean>;
  paginationTokens: PaginationTokens;
  nextPaginationToken: string | null;
  page: number;
  pageSize: number;
  filters: {
    available: NotificationListAvailableFilters;
    active: {
      severity: Optional<Severity>;
      type: Optional<Type>;
      categories: Optional<string[]>;
      sites: Optional<string[]>;
      machines: Optional<string[]>;
    };
    isLoading: boolean;
  };
  sortOptions: {
    field: Optional<string>;
    order: Optional<SortOrders>;
  };
}

export const initialState: NotificationListState = {
  data: null,
  totalCount: null,
  isLoading: null,
  isLoadingMoreData: null,
  paginationTokens: {},
  nextPaginationToken: '',
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  filters: {
    available: {
      severities: [],
      types: [],
      categories: [],
      sites: [],
      machines: undefined,
    },
    active: {
      severity: undefined,
      type: undefined,
      categories: undefined,
      sites: undefined,
      machines: undefined,
    },
    isLoading: false,
  },
  sortOptions: {
    field: null,
    order: null,
  },
};

export const notificationListReducer = (state = initialState, action: AnyAction): NotificationListState =>
  produce(state, draft => {
    switch (action.type) {
      case NotificationListActions.GET_NOTIFICATION_LIST_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_SUCCESS: {
        const {
          notifications: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;
        draft.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_REQUEST: {
        draft.isLoadingMoreData = true;
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_SUCCESS: {
        const {
          notifications: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;
        draft.isLoadingMoreData = false;
        draft.data = (draft.data || []).concat(data);
        draft.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MORE_DATA_ERROR: {
        draft.isLoadingMoreData = false;
        return draft;
      }

      case NotificationListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        return draft;
      }

      case NotificationListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        return draft;
      }

      case NotificationListActions.RESET_STATE: {
        return initialState;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available = action.payload;

        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_SEVERITY_FILTER: {
        const { severity } = action.payload;

        draft.filters.active.severity = severity;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_TYPE_FILTER: {
        const { type } = action.payload;

        draft.filters.active.type = type;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_CATEGORIES_FILTER: {
        const { categories } = action.payload;

        draft.filters.active.categories = categories;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_SITES_FILTER: {
        const { sites } = action.payload;

        draft.filters.active.sites = sites;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_MACHINES_FILTER: {
        const { machines } = action.payload;

        draft.filters.active.machines = machines;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.sortOptions.field = field;
        return draft;
      }

      case NotificationListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.sortOptions.order = order;
        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_REQUEST: {
        draft.filters.isLoading = true;
        // not actual loading because it's to wait for machines
        // but we simulate it to avoid sequence loading of machines & notifications then
        draft.isLoading = true;

        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_SUCCESS: {
        draft.filters.isLoading = false;
        draft.isLoading = false; // may omit this to display icon loading seamlessly
        draft.filters.available.machines = action.payload.machines.data;

        return draft;
      }

      case NotificationListActions.GET_NOTIFICATION_LIST_MACHINE_LIST_FILTER_ERROR: {
        draft.filters.isLoading = false;
        draft.isLoading = false;

        return draft;
      }

      default:
        return draft;
    }
  });
