import { ResponseNotificationsList, Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';

export interface NotificationList {
  notifications: ResponseNotificationsList;
}

export interface NotificationListAvailableFilters {
  severities: string[];
  types: string[];
  categories: string[];
  sites: Site[];
  machines: Optional<MachineAvailableToFilter[]>;
}

export enum NotificationType {
  LocationStatus = 'LOCATION_STATUS',
  Reminder = 'REMINDER',
  Undefined = 'UNDEFINED',
}
