import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { AwsDateFormatPattern } from '../../../../../../lib/utils/date-handling/DateTime.types';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import {
  AnalyticsForm,
  AnalyticsLink,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Machine } from '../../../../machine-inventory/interfaces/Machine.types';
import { ReminderModalsSelectors } from '../../state/reminderModalsSelectors';
import { ReminderActions } from '../../../state/reminderActions';
import { ReminderModalsActions } from '../../state/reminderModalsActions';
import { StyledCreateReminderModal } from './CreateReminderModal.styles';
import { TopicSuggestionAutoComplete } from './TopicSuggestionAutoComplete/TopicSuggestionAutoComplete';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { RepetitionInterval } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { LegacySelect } from 'lib/components/LegacySelect/LegacySelect';
import { TextArea } from 'lib/components/TextArea/TextArea';
import { TimePicker } from 'lib/components/TimePicker/TimePicker';
import { DatePicker } from 'lib/components/DatePicker/DatePicker';
import { DateTime } from 'lib/utils/date-handling/DateTime';

interface DisabledTimes {
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
}

const CREATE_REMINDER_MODAL_REPETITION_VALUES = [
  { value: RepetitionInterval.Once, content: 'reminder.reminderCreate.repetition.once' },
  { value: RepetitionInterval.Weekly, content: 'reminder.reminderCreate.repetition.weekly' },
  { value: RepetitionInterval.Monthly, content: 'reminder.reminderCreate.repetition.monthly' },
  { value: RepetitionInterval.Every_3Months, content: 'reminder.reminderCreate.repetition.every3months' },
  { value: RepetitionInterval.Every_6Months, content: 'reminder.reminderCreate.repetition.every6months' },
  { value: RepetitionInterval.Yearly, content: 'reminder.reminderCreate.repetition.yearly' },
];

export interface CreateReminderModalProps {
  machine: Machine;
}

export const CreateReminderModal = ({ machine }: CreateReminderModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTimezone = DateTime.getBrowserTimeZone();
  const [formReminderInstance] = Form.useForm();
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [time, setTime] = useState<Dayjs>(dayjs());

  const [repetition, setRepetition] = useState<RepetitionInterval | undefined>(undefined);
  const [isSelectedToday, setIsSelectedToday] = useState(false);

  const isVisible = useSelector(ReminderModalsSelectors.selectIsReminderCreateModalVisible);
  const isLoading = useSelector(ReminderModalsSelectors.selectIsCreateReminderModalLoading);

  const startAt =
    date && time
      ? new Date(`${date?.format(AwsDateFormatPattern.DATE)}T${time?.format(AwsDateFormatPattern.TIME)}`).toISOString()
      : '';

  const disabledDatesPredicate = useCallback(
    (current: Dayjs): boolean => current && current.valueOf() < DateTime.oneDayAgo().getTime(),
    []
  );

  const disabledHoursPredicate = useCallback(() => {
    const hours = [];
    const currentHour = dayjs().tz(currentTimezone).hour();

    for (let i = 0; i < currentHour; i += 1) {
      hours.push(i);
    }

    return hours;
  }, [currentTimezone]);

  const disabledMinutesPredicate = useCallback(
    (selectedHour: number) => {
      const minutes = [];
      const currentHour = dayjs().tz(currentTimezone).hour();
      const currentMinute = dayjs().tz(currentTimezone).minute();

      if (selectedHour === currentHour) {
        for (let i = 0; i <= currentMinute; i += 1) {
          minutes.push(i);
        }
      }

      return minutes;
    },
    [currentTimezone]
  );

  const getDisabledTimePredicates = () => (): DisabledTimes => ({
    disabledHours: () => (isSelectedToday ? disabledHoursPredicate() : []),
    disabledMinutes: (hour: number) => (isSelectedToday ? disabledMinutesPredicate(hour) : []),
  });

  const onTimeSelect = useCallback((selectedTime: Dayjs): void => {
    if (selectedTime.isAfter(dayjs())) {
      setTime(dayjs());
      return;
    }

    setTime(selectedTime);
  }, []);

  const onDateSelect = useCallback(
    (value: Dayjs): void => {
      if (value.toDate().getDate() === DateTime.today().getDate()) {
        const currentTime = dayjs().tz(currentTimezone);

        setIsSelectedToday(true);
        formReminderInstance.setFieldValue('time', currentTime);
      } else {
        setIsSelectedToday(false);
        formReminderInstance.setFieldValue('time', undefined);
      }
    },
    [formReminderInstance, currentTimezone]
  );

  const handleValuesChange = useCallback((): void => {
    setTopic(formReminderInstance.getFieldsValue().topic);
    setDescription(formReminderInstance.getFieldsValue().description);
    setRepetition(formReminderInstance.getFieldsValue().repetition);
    setDate(formReminderInstance.getFieldValue('date'));
    setTime(formReminderInstance.getFieldValue('time'));
  }, [formReminderInstance]);

  const handleOk = useCallback(() => {
    formReminderInstance.submit();
  }, [formReminderInstance]);

  const handleCancel = useCallback(() => {
    dispatch(ReminderModalsActions.hideReminderCreateModal());
    formReminderInstance.resetFields();
  }, [dispatch, formReminderInstance]);
  const onFinish = useCallback(() => {
    if (isNil(date) || isNil(time) || isNil(repetition)) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.ADD_REMINDER,
    });

    dispatch(
      ReminderActions.createReminderRequest({
        description: description || '',
        localRepetitiveDate: date?.format(AwsDateFormatPattern.DATE) || '',
        localRepetitiveTime: time?.format(AwsDateFormatPattern.TIME) || '',
        machineId: machine?.id as string,
        repetition,
        startAt,
        timezone: currentTimezone,
        topic,
      })
    );
  }, [
    date,
    time,
    repetition,
    analyticsLinkActivated,
    dispatch,
    description,
    machine?.id,
    startAt,
    currentTimezone,
    topic,
  ]);

  useEffect(() => {
    formReminderInstance.resetFields();
    setIsSelectedToday(false);
  }, [formReminderInstance, isVisible]);

  useAnalyticsSetForm({
    name: AnalyticsForm.ADD_REMINDER,
    fields: {
      description: description || '',
      localRepetitiveDate: date?.format(AwsDateFormatPattern.DATE) || '',
      localRepetitiveTime: time?.format(AwsDateFormatPattern.TIME) || '',
      machineId: machine?.id as string,
      repetition,
      startAt,
      timezone: currentTimezone,
      topic,
    },
    isVisible,
  });

  return (
    <StyledCreateReminderModal
      className="reminder-create-modal"
      title={t('reminder.reminderCreate.title')}
      width={600}
      open={isVisible}
      footer={[
        <LegacySecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('reminder.reminderCreate.button.setReminder')}
        </LegacyPrimaryButton>,
      ]}
    >
      <Form
        form={formReminderInstance}
        name="reminder-create"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Col span={24}>
            <p className="reminder-create-modal__description">{t('reminder.reminderCreate.description')}</p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <h3 className="reminder-create-modal__sub-heading">
              {machine?.name} - {machine?.type?.name}
            </h3>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="topic"
              label={t('reminder.reminderCreate.label.topic')}
              className="reminder-create-modal__input-group"
              required
              rules={[{ required: true, message: t('reminder.reminderCreate.errors.topicRequired') }]}
            >
              <TopicSuggestionAutoComplete value={topic} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('reminder.reminderCreate.label.description')}
              className="reminder-create-modal__input-group"
            >
              <TextArea
                className="reminder-create-modal__text-area"
                placeholder={t('reminder.reminderCreate.placeholder.description')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="date"
              label={t('reminder.reminderCreate.label.date')}
              className="reminder-create-modal__input-group"
              required
              rules={[{ required: true, message: t('reminder.reminderCreate.errors.dateRequired') }]}
            >
              <DatePicker disabledDate={disabledDatesPredicate} onOk={onDateSelect} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="time"
              label={t('reminder.reminderCreate.label.time')}
              className="reminder-create-modal__input-group"
              required
              rules={[{ required: true, message: t('reminder.reminderCreate.errors.timeRequired') }]}
            >
              <TimePicker onOk={onTimeSelect} value={time} disabledTime={getDisabledTimePredicates()} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="repetition"
              label={t('reminder.reminderCreate.label.repetition')}
              className="reminder-create-modal__input-group"
              required
              rules={[{ required: true, message: t('reminder.reminderCreate.errors.repetitionRequired') }]}
            >
              <LegacySelect
                className="reminder-create-modal__select"
                placeholder={t('reminder.reminderCreate.placeholder.repetition')}
                options={CREATE_REMINDER_MODAL_REPETITION_VALUES.map(({ value, content }) => ({
                  label: t(content),
                  value,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledCreateReminderModal>
  );
};
