import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import {
  IGetPriorityNotificationsErrorAction,
  IGetPriorityNotificationsRequestAction,
  IGetPriorityNotificationsSuccessAction,
  PriorityNotificationsActions,
} from './priorityNotificationsActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { NotificationList } from 'app/modules/notification/interfaces/Notification.types';

export function* getPriorityNotificationsSaga(
  action: IGetPriorityNotificationsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<NotificationList>
  | PutEffect<IGetPriorityNotificationsSuccessAction>
  | PutEffect<IGetPriorityNotificationsErrorAction>,
  void,
  IDependencies
> {
  const { notificationService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(notificationService.listShort, action.payload);
    yield* put(PriorityNotificationsActions.getPriorityNotificationsSuccess(response));
  } catch (error) {
    yield* put(
      PriorityNotificationsActions.getPriorityNotificationsError({
        error,
      })
    );
  }
}

export function* priorityNotificationsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(PriorityNotificationsActions.GET_PRIORITY_NOTIFICATIONS_REQUEST, getPriorityNotificationsSaga);
}
