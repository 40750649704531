import styled from 'styled-components';

export const StyledNotificationList = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('notification-list')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('notification-list')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('notification-list')}

  font-family: ${(props): string => props.theme.fonts.p0};
  font-size: ${(props): string => props.theme.fontSizes.p0};
  min-height: inherit;

  .notification-list__header {
    background-color: transparent;
  }

  .notification-list__page-info {
    display: flex;
    justify-content: space-between;
  }

  .button-base.button__size-s.notification-list__subscription-button {
    padding: 6px 12px 5px;
    width: 229px;
    height: 40px;
    border: 1px solid;
    border-color: ${(props): string => props.theme.colors.grey};
    span {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
    }
  }

  .notification-list__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .notification-list__filter {
    display: flex;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.base};
    padding-top: 24px;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};

        .ant-select-selection-overflow-item {
          max-width: 90%;
          ${(props): string => props.theme.mixins.truncateText}
        }

        .ant-select-selection-overflow-item > * {
          ${(props): string => props.theme.mixins.truncateText}
        }
      }
    }
  }

  .notification-list__filter-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }

  .notification-list__filter--button__icon {
    width: 14px;

    svg {
      margin-bottom: -2px;
    }
  }

  .notification-list__select-wrapper {
    min-width: 260px;
  }

  .notification-list__site-select-wrapper {
    min-width: 235px;
  }

  .notification-list__name-select-wrapper {
    min-width: 140px;
  }

  .notification-list__category-select-wrapper {
    min-width: 150px;
  }

  .notification-list__filter-select {
    width: 100%;
  }

  .notification-list__filter-wrapper-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: ${(props): string => props.theme.spacing.base};
    margin-top: ${(props): string => props.theme.spacing.lg};
  }

  .notification-list__filter-wrapper-container--hidden {
    display: none;
  }

  .button-active {
    background-color: ${(props): string => props.theme.colors.black} !important;
    color: white !important;
    path {
      fill: ${(props): string => props.theme.colors.white};
    }
  }

  .notification-list__filter-button-counter {
    line-height: 20px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;

    color: ${(props): string => props.theme.colors.black};
    background-color: ${(props): string => props.theme.colors.white};
  }

  // Needs to match scroll.y prop on <Table>
  .ant-table-container,
  .ant-table-body {
    // Disable until decision is made from PO side
    // min-height: 600px;
  }

  .notification-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
  }

  .ant-table-container {
    table {
      .ant-table-thead {
        > tr > th {
          line-height: 21px;
        }
      }
      .ant-table-tbody {
        tr > td {
          line-height: 21px;

          &.notification-list__icon-column {
            ${(props): string => props.theme.mixins.flexCenter}

            span {
              ${(props): string => props.theme.mixins.flexCenter}
            }
          }
        }
      }
    }
  }
`;
