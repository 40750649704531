import styled from 'styled-components';

export const StyledService = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('service')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('service')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('service')}

  min-height: inherit;

  .service__header {
    background-color: transparent;
  }

  .service__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .service__description {
    ${(props): string => props.theme.fontStyles.p0};
    color: ${(props): string => props.theme.colors.black};
    overflow: unset;
    margin-bottom: 15px;
  }

  .service__body-content {
    .service__body-content-img-block {
      width: 664px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        filter: brightness(0.7);
      }

      .service__body-content-img-block-image-title {
        ${(props): string => props.theme.fontStyles.heading1}
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 0 0 0;
        color: ${(props): string => props.theme.colors.white};
        text-transform: uppercase;
        padding: 0 0 42px 30px;
        width: 390px;
      }
    }

    .service__body-info {
      flex: 1;
      background: ${(props): string => props.theme.colors.brightGrey};
      padding: 30px;

      p.service__body-info-title {
        ${(props): string => props.theme.fontStyles.heading3};
      }

      .service__body-info-item {
        margin: 0 0 24px 0;

        p.service__body-info-item-title {
          ${(props): string => props.theme.fontStyles.caption.bold}
          margin: 0 0 5px 0;

          .service__body-info-item-title-icon {
            width: 13px;
            height: 13px;
            margin: 0 5px 0 0;
          }
        }

        p.service__body-info-item-content {
          ${(props): string => props.theme.fontStyles.p1.news}
        }
      }
    }
  }

  .service__container {
    display: flex;
    width: ${(props): number => props.theme.constants.narrowContainer.maxWidth}px;
  }
`;
